@font-face {
  font-family: 'AcuminPro-Light';
  src: url("../fonts/AcuminPro-Light.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/AcuminPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/AcuminPro-Light.woff2") format("woff2"), url("../fonts/AcuminPro-Light.woff") format("woff"), url("../fonts/AcuminPro-Light.ttf") format("truetype"), url("../fonts/AcuminPro-Light.svg#AcuminPro-Light") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'AcuminPro-Medium';
  src: url("../fonts/AcuminPro-Medium.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/AcuminPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/AcuminPro-Medium.woff2") format("woff2"), url("../fonts/AcuminPro-Medium.woff") format("woff"), url("../fonts/AcuminPro-Medium.ttf") format("truetype"), url("../fonts/AcuminPro-Medium.svg#AcuminPro-Medium") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'AcuminPro-Semibold';
  src: url("../fonts/AcuminPro-Semibold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/AcuminPro-Semibold.eot?#iefix") format("embedded-opentype"), url("../fonts/AcuminPro-Semibold.woff2") format("woff2"), url("../fonts/AcuminPro-Semibold.woff") format("woff"), url("../fonts/AcuminPro-Semibold.ttf") format("truetype"), url("../fonts/AcuminPro-Semibold.svg#AcuminPro-Semibold") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'AcuminPro-Italic';
  src: url("../fonts/AcuminPro-Italic.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/AcuminPro-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/AcuminPro-Italic.woff2") format("woff2"), url("../fonts/AcuminPro-Italic.woff") format("woff"), url("../fonts/AcuminPro-Italic.ttf") format("truetype"), url("../fonts/AcuminPro-Italic.svg#AcuminPro-Italic") format("svg");
  /* Legacy iOS */ }

@font-face {
  font-family: 'AcuminPro-Cond-Bold';
  src: url("../fonts/AcuminProCond-Bold.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/AcuminProCond-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/AcuminProCond-Bold.woff2") format("woff2"), url("../fonts/AcuminProCond-Bold.woff") format("woff"), url("../fonts/AcuminProCond-Bold.ttf") format("truetype"), url("../fonts/AcuminProCond-Bold.svg#AcuminProCond-Bold") format("svg");
  /* Legacy iOS */ }

.row-v4 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px; }
  .row-v4.no-gutters {
    margin-right: 0;
    margin-left: 0; }
    .row-v4.no-gutters > [class^="col-"] {
      padding-right: 0;
      padding-left: 0; }

.row-v4-column {
  display: flex;
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
  margin-right: -10px;
  margin-left: -10px; }

.form-row-v4 {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row-v4 > .col-v4,
  .form-row-v4 > [class*=col-v4-] {
    padding-right: 5px;
    padding-left: 5px; }

.col-v4-1,
.col-v4-sm-1,
.col-v4-md-1,
.col-v4-lg-1,
.col-v4-xl-1 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-2,
.col-v4-sm-2,
.col-v4-md-2,
.col-v4-lg-2,
.col-v4-xl-2 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-3,
.col-v4-sm-3,
.col-v4-md-3,
.col-v4-lg-3,
.col-v4-xl-3 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-4,
.col-v4-sm-4,
.col-v4-md-4,
.col-v4-lg-4,
.col-v4-xl-4 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-5,
.col-v4-sm-5,
.col-v4-md-5,
.col-v4-lg-5,
.col-v4-xl-5 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-6,
.col-v4-sm-6,
.col-v4-md-6,
.col-v4-lg-6,
.col-v4-xl-6 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-7,
.col-v4-sm-7,
.col-v4-md-7,
.col-v4-lg-7,
.col-v4-xl-7 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-8,
.col-v4-sm-8,
.col-v4-md-8,
.col-v4-lg-8,
.col-v4-xl-8 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-9,
.col-v4-sm-9,
.col-v4-md-9,
.col-v4-lg-9,
.col-v4-xl-9 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-10,
.col-v4-sm-10,
.col-v4-md-10,
.col-v4-lg-10,
.col-v4-xl-10 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-11,
.col-v4-sm-11,
.col-v4-md-11,
.col-v4-lg-11,
.col-v4-xl-11 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4-12,
.col-v4-sm-12,
.col-v4-md-12,
.col-v4-lg-12,
.col-v4-xl-12 {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4,
.col-v4-auto,
.col-v4-sm,
.col-v4-sm-auto,
.col-v4-md,
.col-v4-md-auto,
.col-v4-lg,
.col-v4-lg-auto,
.col-v4-xl,
.col-v4-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.col-v4 {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%; }

.col-v4-auto {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-v4-1 {
  -ms-flex: 0 0 8.33333%;
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-v4-2 {
  -ms-flex: 0 0 16.66667%;
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-v4-3 {
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%; }

.col-v4-4 {
  -ms-flex: 0 0 33.33333%;
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-v4-5 {
  -ms-flex: 0 0 41.66667%;
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-v4-6 {
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%; }

.col-v4-7 {
  -ms-flex: 0 0 58.33333%;
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-v4-8 {
  -ms-flex: 0 0 66.66667%;
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-v4-9 {
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%; }

.col-v4-10 {
  -ms-flex: 0 0 83.33333%;
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-v4-11 {
  -ms-flex: 0 0 91.66667%;
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-v4-12 {
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -ms-flex-order: -1;
  order: -1; }

.order-1 {
  -ms-flex-order: 1;
  order: 1; }

.order-2 {
  -ms-flex-order: 2;
  order: 2; }

.order-3 {
  -ms-flex-order: 3;
  order: 3; }

.order-4 {
  -ms-flex-order: 4;
  order: 4; }

.order-5 {
  -ms-flex-order: 5;
  order: 5; }

.order-6 {
  -ms-flex-order: 6;
  order: 6; }

.order-7 {
  -ms-flex-order: 7;
  order: 7; }

.order-8 {
  -ms-flex-order: 8;
  order: 8; }

.order-9 {
  -ms-flex-order: 9;
  order: 9; }

.order-10 {
  -ms-flex-order: 10;
  order: 10; }

.order-11 {
  -ms-flex-order: 11;
  order: 11; }

.order-12 {
  -ms-flex-order: 12;
  order: 12; }

.order-last {
  -ms-flex-order: 13;
  order: 13; }

@media (min-width: 576px) {
  .col-v4-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-v4-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-v4-sm-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-v4-sm-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-v4-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-v4-sm-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-v4-sm-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-v4-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-v4-sm-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-v4-sm-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-v4-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-v4-sm-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-v4-sm-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-v4-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-sm-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-sm-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-sm-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-sm-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-sm-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-sm-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-sm-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-sm-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-sm-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-sm-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-sm-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-sm-12 {
    -ms-flex-order: 12;
    order: 12; }
  .order-sm-last {
    -ms-flex-order: 13;
    order: 13; } }

@media (min-width: 768px) {
  .col-v4-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-v4-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-v4-md-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-v4-md-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-v4-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-v4-md-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-v4-md-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-v4-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-v4-md-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-v4-md-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-v4-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-v4-md-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-v4-md-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-v4-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-md-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-md-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-md-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-md-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-md-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-md-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-md-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-md-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-md-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-md-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-md-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-md-12 {
    -ms-flex-order: 12;
    order: 12; }
  .order-md-last {
    -ms-flex-order: 13;
    order: 13; } }

@media (min-width: 992px) {
  .col-v4-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-v4-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-v4-lg-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-v4-lg-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-v4-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-v4-lg-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-v4-lg-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-v4-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-v4-lg-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-v4-lg-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-v4-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-v4-lg-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-v4-lg-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-v4-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-lg-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-lg-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-lg-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-lg-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-lg-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-lg-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-lg-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-lg-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-lg-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-lg-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-lg-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-lg-12 {
    -ms-flex-order: 12;
    order: 12; }
  .order-lg-last {
    -ms-flex-order: 13;
    order: 13; } }

@media (min-width: 1200px) {
  .col-v4-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%; }
  .col-v4-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-v4-xl-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-v4-xl-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-v4-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%; }
  .col-v4-xl-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-v4-xl-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-v4-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%; }
  .col-v4-xl-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-v4-xl-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-v4-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%; }
  .col-v4-xl-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-v4-xl-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-v4-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -ms-flex-order: -1;
    order: -1; }
  .order-xl-1 {
    -ms-flex-order: 1;
    order: 1; }
  .order-xl-2 {
    -ms-flex-order: 2;
    order: 2; }
  .order-xl-3 {
    -ms-flex-order: 3;
    order: 3; }
  .order-xl-4 {
    -ms-flex-order: 4;
    order: 4; }
  .order-xl-5 {
    -ms-flex-order: 5;
    order: 5; }
  .order-xl-6 {
    -ms-flex-order: 6;
    order: 6; }
  .order-xl-7 {
    -ms-flex-order: 7;
    order: 7; }
  .order-xl-8 {
    -ms-flex-order: 8;
    order: 8; }
  .order-xl-9 {
    -ms-flex-order: 9;
    order: 9; }
  .order-xl-10 {
    -ms-flex-order: 10;
    order: 10; }
  .order-xl-11 {
    -ms-flex-order: 11;
    order: 11; }
  .order-xl-12 {
    -ms-flex-order: 12;
    order: 12; }
  .order-xl-last {
    -ms-flex-order: 13;
    order: 13; } }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 0.25rem !important; }

.pt-2 {
  padding-top: 0.5rem !important; }

.pt-3 {
  padding-top: 1rem !important; }

.pt-4 {
  padding-top: 2rem !important; }

.pt-5 {
  padding-top: 4rem !important; }

.pr-0 {
  padding-right: 0 !important; }

.pr-1 {
  padding-right: 0.25rem !important; }

.pr-2 {
  padding-right: 0.5rem !important; }

.pr-3 {
  padding-right: 1rem !important; }

.pr-4 {
  padding-right: 2rem !important; }

.pr-5 {
  padding-right: 4rem !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 0.25rem !important; }

.pb-2 {
  padding-bottom: 0.5rem !important; }

.pb-3 {
  padding-bottom: 1rem !important; }

.pb-4 {
  padding-bottom: 2rem !important; }

.pb-5 {
  padding-bottom: 4rem !important; }

.pl-0 {
  padding-left: 0 !important; }

.pl-1 {
  padding-left: 0.25rem !important; }

.pl-2 {
  padding-left: 0.5rem !important; }

.pl-3 {
  padding-left: 1rem !important; }

.pl-4 {
  padding-left: 2rem !important; }

.pl-5 {
  padding-left: 4rem !important; }

.px-0 {
  padding-right: 0 !important; }

.px-1 {
  padding-right: 0.25rem !important; }

.px-2 {
  padding-right: 0.5rem !important; }

.px-3 {
  padding-right: 1rem !important; }

.px-4 {
  padding-right: 2rem !important; }

.px-5 {
  padding-right: 4rem !important; }

.px-0 {
  padding-left: 0 !important; }

.px-1 {
  padding-left: 0.25rem !important; }

.px-2 {
  padding-left: 0.5rem !important; }

.px-3 {
  padding-left: 1rem !important; }

.px-4 {
  padding-left: 2rem !important; }

.px-5 {
  padding-left: 4rem !important; }

.py-0 {
  padding-top: 0 !important; }

.py-1 {
  padding-top: 0.25rem !important; }

.py-2 {
  padding-top: 0.5rem !important; }

.py-3 {
  padding-top: 1rem !important; }

.py-4 {
  padding-top: 2rem !important; }

.py-5 {
  padding-top: 4rem !important; }

.py-0 {
  padding-bottom: 0 !important; }

.py-1 {
  padding-bottom: 0.25rem !important; }

.py-2 {
  padding-bottom: 0.5rem !important; }

.py-3 {
  padding-bottom: 1rem !important; }

.py-4 {
  padding-bottom: 2rem !important; }

.py-5 {
  padding-bottom: 4rem !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 0.25rem !important; }

.mt-2 {
  margin-top: 0.5rem !important; }

.mt-3 {
  margin-top: 1rem !important; }

.mt-4 {
  margin-top: 2rem !important; }

.mt-5 {
  margin-top: 4rem !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-1 {
  margin-right: 0.25rem !important; }

.mr-2 {
  margin-right: 0.5rem !important; }

.mr-3 {
  margin-right: 1rem !important; }

.mr-4 {
  margin-right: 2rem !important; }

.mr-5 {
  margin-right: 4rem !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 0.25rem !important; }

.mb-2 {
  margin-bottom: 0.5rem !important; }

.mb-3 {
  margin-bottom: 1rem !important; }

.mb-4 {
  margin-bottom: 2rem !important; }

.mb-5 {
  margin-bottom: 4rem !important; }

.ml-0 {
  margin-left: 0 !important; }

.ml-1 {
  margin-left: 0.25rem !important; }

.ml-2 {
  margin-left: 0.5rem !important; }

.ml-3 {
  margin-left: 1rem !important; }

.ml-4 {
  margin-left: 2rem !important; }

.ml-5 {
  margin-left: 4rem !important; }

.mx-0 {
  margin-right: 0 !important; }

.mx-1 {
  margin-right: 0.25rem !important; }

.mx-2 {
  margin-right: 0.5rem !important; }

.mx-3 {
  margin-right: 1rem !important; }

.mx-4 {
  margin-right: 2rem !important; }

.mx-5 {
  margin-right: 4rem !important; }

.mx-0 {
  margin-left: 0 !important; }

.mx-1 {
  margin-left: 0.25rem !important; }

.mx-2 {
  margin-left: 0.5rem !important; }

.mx-3 {
  margin-left: 1rem !important; }

.mx-4 {
  margin-left: 2rem !important; }

.mx-5 {
  margin-left: 4rem !important; }

.my-0 {
  margin-top: 0 !important; }

.my-1 {
  margin-top: 0.25rem !important; }

.my-2 {
  margin-top: 0.5rem !important; }

.my-3 {
  margin-top: 1rem !important; }

.my-4 {
  margin-top: 2rem !important; }

.my-5 {
  margin-top: 4rem !important; }

.my-0 {
  margin-bottom: 0 !important; }

.my-1 {
  margin-bottom: 0.25rem !important; }

.my-2 {
  margin-bottom: 0.5rem !important; }

.my-3 {
  margin-bottom: 1rem !important; }

.my-4 {
  margin-bottom: 2rem !important; }

.my-5 {
  margin-bottom: 4rem !important; }

@media (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 0.25rem !important; }
  .pt-sm-2 {
    padding-top: 0.5rem !important; }
  .pt-sm-3 {
    padding-top: 1rem !important; }
  .pt-sm-4 {
    padding-top: 2rem !important; }
  .pt-sm-5 {
    padding-top: 4rem !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pr-sm-1 {
    padding-right: 0.25rem !important; }
  .pr-sm-2 {
    padding-right: 0.5rem !important; }
  .pr-sm-3 {
    padding-right: 1rem !important; }
  .pr-sm-4 {
    padding-right: 2rem !important; }
  .pr-sm-5 {
    padding-right: 4rem !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pb-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pb-sm-3 {
    padding-bottom: 1rem !important; }
  .pb-sm-4 {
    padding-bottom: 2rem !important; }
  .pb-sm-5 {
    padding-bottom: 4rem !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .pl-sm-1 {
    padding-left: 0.25rem !important; }
  .pl-sm-2 {
    padding-left: 0.5rem !important; }
  .pl-sm-3 {
    padding-left: 1rem !important; }
  .pl-sm-4 {
    padding-left: 2rem !important; }
  .pl-sm-5 {
    padding-left: 4rem !important; }
  .px-sm-0 {
    padding-right: 0 !important; }
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .px-sm-3 {
    padding-right: 1rem !important; }
  .px-sm-4 {
    padding-right: 2rem !important; }
  .px-sm-5 {
    padding-right: 4rem !important; }
  .px-sm-0 {
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .px-sm-3 {
    padding-left: 1rem !important; }
  .px-sm-4 {
    padding-left: 2rem !important; }
  .px-sm-5 {
    padding-left: 4rem !important; }
  .py-sm-0 {
    padding-top: 0 !important; }
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .py-sm-3 {
    padding-top: 1rem !important; }
  .py-sm-4 {
    padding-top: 2rem !important; }
  .py-sm-5 {
    padding-top: 4rem !important; }
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .py-sm-4 {
    padding-bottom: 2rem !important; }
  .py-sm-5 {
    padding-bottom: 4rem !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 0.25rem !important; }
  .mt-sm-2 {
    margin-top: 0.5rem !important; }
  .mt-sm-3 {
    margin-top: 1rem !important; }
  .mt-sm-4 {
    margin-top: 2rem !important; }
  .mt-sm-5 {
    margin-top: 4rem !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mr-sm-1 {
    margin-right: 0.25rem !important; }
  .mr-sm-2 {
    margin-right: 0.5rem !important; }
  .mr-sm-3 {
    margin-right: 1rem !important; }
  .mr-sm-4 {
    margin-right: 2rem !important; }
  .mr-sm-5 {
    margin-right: 4rem !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 0.25rem !important; }
  .mb-sm-2 {
    margin-bottom: 0.5rem !important; }
  .mb-sm-3 {
    margin-bottom: 1rem !important; }
  .mb-sm-4 {
    margin-bottom: 2rem !important; }
  .mb-sm-5 {
    margin-bottom: 4rem !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .ml-sm-1 {
    margin-left: 0.25rem !important; }
  .ml-sm-2 {
    margin-left: 0.5rem !important; }
  .ml-sm-3 {
    margin-left: 1rem !important; }
  .ml-sm-4 {
    margin-left: 2rem !important; }
  .ml-sm-5 {
    margin-left: 4rem !important; }
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mx-sm-4 {
    margin-right: 2rem !important; }
  .mx-sm-5 {
    margin-right: 4rem !important; }
  .mx-sm-0 {
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .mx-sm-4 {
    margin-left: 2rem !important; }
  .mx-sm-5 {
    margin-left: 4rem !important; }
  .my-sm-0 {
    margin-top: 0 !important; }
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .my-sm-3 {
    margin-top: 1rem !important; }
  .my-sm-4 {
    margin-top: 2rem !important; }
  .my-sm-5 {
    margin-top: 4rem !important; }
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .my-sm-4 {
    margin-bottom: 2rem !important; }
  .my-sm-5 {
    margin-bottom: 4rem !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .ml-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 0.25rem !important; }
  .pt-md-2 {
    padding-top: 0.5rem !important; }
  .pt-md-3 {
    padding-top: 1rem !important; }
  .pt-md-4 {
    padding-top: 2rem !important; }
  .pt-md-5 {
    padding-top: 4rem !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pr-md-1 {
    padding-right: 0.25rem !important; }
  .pr-md-2 {
    padding-right: 0.5rem !important; }
  .pr-md-3 {
    padding-right: 1rem !important; }
  .pr-md-4 {
    padding-right: 2rem !important; }
  .pr-md-5 {
    padding-right: 4rem !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 0.25rem !important; }
  .pb-md-2 {
    padding-bottom: 0.5rem !important; }
  .pb-md-3 {
    padding-bottom: 1rem !important; }
  .pb-md-4 {
    padding-bottom: 2rem !important; }
  .pb-md-5 {
    padding-bottom: 4rem !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .pl-md-1 {
    padding-left: 0.25rem !important; }
  .pl-md-2 {
    padding-left: 0.5rem !important; }
  .pl-md-3 {
    padding-left: 1rem !important; }
  .pl-md-4 {
    padding-left: 2rem !important; }
  .pl-md-5 {
    padding-left: 4rem !important; }
  .px-md-0 {
    padding-right: 0 !important; }
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .px-md-3 {
    padding-right: 1rem !important; }
  .px-md-4 {
    padding-right: 2rem !important; }
  .px-md-5 {
    padding-right: 4rem !important; }
  .px-md-0 {
    padding-left: 0 !important; }
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .px-md-3 {
    padding-left: 1rem !important; }
  .px-md-4 {
    padding-left: 2rem !important; }
  .px-md-5 {
    padding-left: 4rem !important; }
  .py-md-0 {
    padding-top: 0 !important; }
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .py-md-3 {
    padding-top: 1rem !important; }
  .py-md-4 {
    padding-top: 2rem !important; }
  .py-md-5 {
    padding-top: 4rem !important; }
  .py-md-0 {
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .py-md-4 {
    padding-bottom: 2rem !important; }
  .py-md-5 {
    padding-bottom: 4rem !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 0.25rem !important; }
  .mt-md-2 {
    margin-top: 0.5rem !important; }
  .mt-md-3 {
    margin-top: 1rem !important; }
  .mt-md-4 {
    margin-top: 2rem !important; }
  .mt-md-5 {
    margin-top: 4rem !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mr-md-1 {
    margin-right: 0.25rem !important; }
  .mr-md-2 {
    margin-right: 0.5rem !important; }
  .mr-md-3 {
    margin-right: 1rem !important; }
  .mr-md-4 {
    margin-right: 2rem !important; }
  .mr-md-5 {
    margin-right: 4rem !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 0.25rem !important; }
  .mb-md-2 {
    margin-bottom: 0.5rem !important; }
  .mb-md-3 {
    margin-bottom: 1rem !important; }
  .mb-md-4 {
    margin-bottom: 2rem !important; }
  .mb-md-5 {
    margin-bottom: 4rem !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .ml-md-1 {
    margin-left: 0.25rem !important; }
  .ml-md-2 {
    margin-left: 0.5rem !important; }
  .ml-md-3 {
    margin-left: 1rem !important; }
  .ml-md-4 {
    margin-left: 2rem !important; }
  .ml-md-5 {
    margin-left: 4rem !important; }
  .mx-md-0 {
    margin-right: 0 !important; }
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mx-md-4 {
    margin-right: 2rem !important; }
  .mx-md-5 {
    margin-right: 4rem !important; }
  .mx-md-0 {
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .mx-md-3 {
    margin-left: 1rem !important; }
  .mx-md-4 {
    margin-left: 2rem !important; }
  .mx-md-5 {
    margin-left: 4rem !important; }
  .my-md-0 {
    margin-top: 0 !important; }
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .my-md-3 {
    margin-top: 1rem !important; }
  .my-md-4 {
    margin-top: 2rem !important; }
  .my-md-5 {
    margin-top: 4rem !important; }
  .my-md-0 {
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .my-md-4 {
    margin-bottom: 2rem !important; }
  .my-md-5 {
    margin-bottom: 4rem !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .ml-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 0.25rem !important; }
  .pt-lg-2 {
    padding-top: 0.5rem !important; }
  .pt-lg-3 {
    padding-top: 1rem !important; }
  .pt-lg-4 {
    padding-top: 2rem !important; }
  .pt-lg-5 {
    padding-top: 4rem !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pr-lg-1 {
    padding-right: 0.25rem !important; }
  .pr-lg-2 {
    padding-right: 0.5rem !important; }
  .pr-lg-3 {
    padding-right: 1rem !important; }
  .pr-lg-4 {
    padding-right: 2rem !important; }
  .pr-lg-5 {
    padding-right: 4rem !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pb-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pb-lg-3 {
    padding-bottom: 1rem !important; }
  .pb-lg-4 {
    padding-bottom: 2rem !important; }
  .pb-lg-5 {
    padding-bottom: 4rem !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .pl-lg-1 {
    padding-left: 0.25rem !important; }
  .pl-lg-2 {
    padding-left: 0.5rem !important; }
  .pl-lg-3 {
    padding-left: 1rem !important; }
  .pl-lg-4 {
    padding-left: 2rem !important; }
  .pl-lg-5 {
    padding-left: 4rem !important; }
  .px-lg-0 {
    padding-right: 0 !important; }
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .px-lg-3 {
    padding-right: 1rem !important; }
  .px-lg-4 {
    padding-right: 2rem !important; }
  .px-lg-5 {
    padding-right: 4rem !important; }
  .px-lg-0 {
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .px-lg-3 {
    padding-left: 1rem !important; }
  .px-lg-4 {
    padding-left: 2rem !important; }
  .px-lg-5 {
    padding-left: 4rem !important; }
  .py-lg-0 {
    padding-top: 0 !important; }
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .py-lg-3 {
    padding-top: 1rem !important; }
  .py-lg-4 {
    padding-top: 2rem !important; }
  .py-lg-5 {
    padding-top: 4rem !important; }
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .py-lg-4 {
    padding-bottom: 2rem !important; }
  .py-lg-5 {
    padding-bottom: 4rem !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 0.25rem !important; }
  .mt-lg-2 {
    margin-top: 0.5rem !important; }
  .mt-lg-3 {
    margin-top: 1rem !important; }
  .mt-lg-4 {
    margin-top: 2rem !important; }
  .mt-lg-5 {
    margin-top: 4rem !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mr-lg-1 {
    margin-right: 0.25rem !important; }
  .mr-lg-2 {
    margin-right: 0.5rem !important; }
  .mr-lg-3 {
    margin-right: 1rem !important; }
  .mr-lg-4 {
    margin-right: 2rem !important; }
  .mr-lg-5 {
    margin-right: 4rem !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 0.25rem !important; }
  .mb-lg-2 {
    margin-bottom: 0.5rem !important; }
  .mb-lg-3 {
    margin-bottom: 1rem !important; }
  .mb-lg-4 {
    margin-bottom: 2rem !important; }
  .mb-lg-5 {
    margin-bottom: 4rem !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .ml-lg-1 {
    margin-left: 0.25rem !important; }
  .ml-lg-2 {
    margin-left: 0.5rem !important; }
  .ml-lg-3 {
    margin-left: 1rem !important; }
  .ml-lg-4 {
    margin-left: 2rem !important; }
  .ml-lg-5 {
    margin-left: 4rem !important; }
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mx-lg-4 {
    margin-right: 2rem !important; }
  .mx-lg-5 {
    margin-right: 4rem !important; }
  .mx-lg-0 {
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .mx-lg-4 {
    margin-left: 2rem !important; }
  .mx-lg-5 {
    margin-left: 4rem !important; }
  .my-lg-0 {
    margin-top: 0 !important; }
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .my-lg-3 {
    margin-top: 1rem !important; }
  .my-lg-4 {
    margin-top: 2rem !important; }
  .my-lg-5 {
    margin-top: 4rem !important; }
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .my-lg-4 {
    margin-bottom: 2rem !important; }
  .my-lg-5 {
    margin-bottom: 4rem !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .ml-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 0.25rem !important; }
  .pt-xl-2 {
    padding-top: 0.5rem !important; }
  .pt-xl-3 {
    padding-top: 1rem !important; }
  .pt-xl-4 {
    padding-top: 2rem !important; }
  .pt-xl-5 {
    padding-top: 4rem !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pr-xl-1 {
    padding-right: 0.25rem !important; }
  .pr-xl-2 {
    padding-right: 0.5rem !important; }
  .pr-xl-3 {
    padding-right: 1rem !important; }
  .pr-xl-4 {
    padding-right: 2rem !important; }
  .pr-xl-5 {
    padding-right: 4rem !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pb-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pb-xl-3 {
    padding-bottom: 1rem !important; }
  .pb-xl-4 {
    padding-bottom: 2rem !important; }
  .pb-xl-5 {
    padding-bottom: 4rem !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .pl-xl-1 {
    padding-left: 0.25rem !important; }
  .pl-xl-2 {
    padding-left: 0.5rem !important; }
  .pl-xl-3 {
    padding-left: 1rem !important; }
  .pl-xl-4 {
    padding-left: 2rem !important; }
  .pl-xl-5 {
    padding-left: 4rem !important; }
  .px-xl-0 {
    padding-right: 0 !important; }
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .px-xl-3 {
    padding-right: 1rem !important; }
  .px-xl-4 {
    padding-right: 2rem !important; }
  .px-xl-5 {
    padding-right: 4rem !important; }
  .px-xl-0 {
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .px-xl-3 {
    padding-left: 1rem !important; }
  .px-xl-4 {
    padding-left: 2rem !important; }
  .px-xl-5 {
    padding-left: 4rem !important; }
  .py-xl-0 {
    padding-top: 0 !important; }
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .py-xl-3 {
    padding-top: 1rem !important; }
  .py-xl-4 {
    padding-top: 2rem !important; }
  .py-xl-5 {
    padding-top: 4rem !important; }
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .py-xl-4 {
    padding-bottom: 2rem !important; }
  .py-xl-5 {
    padding-bottom: 4rem !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 0.25rem !important; }
  .mt-xl-2 {
    margin-top: 0.5rem !important; }
  .mt-xl-3 {
    margin-top: 1rem !important; }
  .mt-xl-4 {
    margin-top: 2rem !important; }
  .mt-xl-5 {
    margin-top: 4rem !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mr-xl-1 {
    margin-right: 0.25rem !important; }
  .mr-xl-2 {
    margin-right: 0.5rem !important; }
  .mr-xl-3 {
    margin-right: 1rem !important; }
  .mr-xl-4 {
    margin-right: 2rem !important; }
  .mr-xl-5 {
    margin-right: 4rem !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 0.25rem !important; }
  .mb-xl-2 {
    margin-bottom: 0.5rem !important; }
  .mb-xl-3 {
    margin-bottom: 1rem !important; }
  .mb-xl-4 {
    margin-bottom: 2rem !important; }
  .mb-xl-5 {
    margin-bottom: 4rem !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .ml-xl-1 {
    margin-left: 0.25rem !important; }
  .ml-xl-2 {
    margin-left: 0.5rem !important; }
  .ml-xl-3 {
    margin-left: 1rem !important; }
  .ml-xl-4 {
    margin-left: 2rem !important; }
  .ml-xl-5 {
    margin-left: 4rem !important; }
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mx-xl-4 {
    margin-right: 2rem !important; }
  .mx-xl-5 {
    margin-right: 4rem !important; }
  .mx-xl-0 {
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .mx-xl-4 {
    margin-left: 2rem !important; }
  .mx-xl-5 {
    margin-left: 4rem !important; }
  .my-xl-0 {
    margin-top: 0 !important; }
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .my-xl-3 {
    margin-top: 1rem !important; }
  .my-xl-4 {
    margin-top: 2rem !important; }
  .my-xl-5 {
    margin-top: 4rem !important; }
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .my-xl-4 {
    margin-bottom: 2rem !important; }
  .my-xl-5 {
    margin-bottom: 4rem !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .ml-xl-auto {
    margin-left: auto !important; } }

.justify-content-between {
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-justify-content: space-between !important; }

.justify-content-center {
  -ms-flex-pack: center !important;
  justify-content: center !important; }

.justify-content-end {
  -ms-flex-pack: end !important;
  justify-content: flex-end !important; }

.justify-content-space-around {
  -ms-flex-pack: justify !important;
  justify-content: space-around !important; }

.justify-content-start {
  -ms-flex-pack: start !important;
  justify-content: flex-start !important; }

.align-items-center {
  -ms-flex-align: center !important;
  align-items: center !important; }

.align-items-start {
  -ms-flex-align: start !important;
  align-items: flex-start !important; }
  @media screen and (min-width: 1280px) {
    .align-items-start .form-check-input-v4 {
      margin-top: 3px; } }
  @media screen and (max-width: 1279px) {
    .align-items-start .form-check-input-v4 {
      margin-top: 1px; } }

.align-items-end {
  -ms-flex-align: end !important;
  align-items: flex-end !important; }

.d-none {
  display: none !important; }

.d-block {
  display: block !important; }

.d-inline-block {
  display: inline-block !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

@media (min-width: 576px) {
  .justify-content-sm-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-justify-content: space-between !important; }
  .justify-content-sm-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-sm-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .align-items-sm-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-sm-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .d-sm-none {
    display: none !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .justify-content-md-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-justify-content: space-between !important; }
  .justify-content-md-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-md-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .align-items-md-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-md-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .d-md-none {
    display: none !important; }
  .d-md-block {
    display: block !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .justify-content-lg-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-justify-content: space-between !important; }
  .justify-content-lg-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-lg-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .align-items-lg-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-lg-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .d-lg-none {
    display: none !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .justify-content-xl-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
    -webkit-justify-content: space-between !important; }
  .justify-content-xl-center {
    -ms-flex-pack: center !important;
    justify-content: center !important; }
  .justify-content-xl-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important; }
  .align-items-xl-center {
    -ms-flex-align: center !important;
    align-items: center !important; }
  .align-items-xl-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
  .d-xl-none {
    display: none !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.position-relative {
  position: relative; }

.form-check-v4 {
  position: relative;
  display: flex;
  margin: 0.75rem 0;
  flex-wrap: nowrap;
  align-items: center; }
  .form-check-v4 .icon-required {
    margin-left: 2px; }
  @media screen and (max-width: 991px) {
    .form-check-v4 input[type='radio'] {
      position: relative;
      top: -1px; } }
  @media screen and (max-width: 767px) {
    .form-check-v4 input[type='radio'] {
      position: relative;
      top: 0px; } }
  .form-check-v4.align-items-start .form-check-label-v4 {
    margin-top: -2px; }

.form-check-label-v4 {
  margin-bottom: 0;
  margin-left: 3px;
  display: inline-flex;
  flex-wrap: wrap; }
  .form-check-label-v4.max-w {
    max-width: calc(100% - 23px); }

.col-form-label-v4 {
  padding-top: calc(.375rem + 1px);
  padding-bottom: calc(.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.form-check-input-v4 {
  display: inline-flex; }

.form-check-inline-v4 {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0;
  margin: 0 1.25rem 0 0;
  height: 19px;
  width: auto; }
  .form-check-inline-v4 .form-check-label-v4 {
    position: relative;
    top: 1px;
    max-width: 100%; }
  .form-check-inline-v4 .form-check-input-v4 {
    margin-top: 1px !important;
    margin-right: .3125rem;
    margin-left: 0 !important; }
  .form-check-inline-v4 .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle; }
  .form-check-inline-v4 + .form-check-inline-v4 {
    margin-top: 0 !important; }
  .form-check-inline-v4:last-child {
    margin-right: 0; }

@media (min-width: 768px) {
  .form-check-inline-v4-md {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin: 0 1.25rem 0 0;
    height: 19px;
    width: auto; }
    .form-check-inline-v4-md .form-check-label-v4 {
      position: relative;
      top: 1px;
      max-width: 100%; }
    .form-check-inline-v4-md .form-check-input-v4 {
      margin-top: 1px !important;
      margin-right: .3125rem;
      margin-left: 0 !important; }
    .form-check-inline-v4-md .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-check-inline-v4-md + .form-check-inline-v4-md {
      margin-top: 0 !important; } }

@media (min-width: 992px) {
  .form-check-inline-v4-lg {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin: 0 1.25rem 0 0;
    height: 19px;
    width: auto; }
    .form-check-inline-v4-lg .form-check-label-v4 {
      position: relative;
      top: 1px;
      max-width: 100%; }
    .form-check-inline-v4-lg .form-check-input-v4 {
      margin-top: 1px !important;
      margin-right: .3125rem;
      margin-left: 0 !important; }
    .form-check-inline-v4-lg .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-check-inline-v4-lg + .form-check-inline-v4-lg {
      margin-top: 0 !important; } }

.input-group-v4 {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%; }
  .input-group-v4 > .form-control {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group-v4 > .form-control:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
  .input-group-v4 .input-group-addon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    white-space: nowrap;
    width: auto; }
    .input-group-v4 .input-group-addon .glyphicon {
      top: 0; }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome.fix-on-safari-5, .row-v4.fix-on-safari-5 {
    display: -webkit-box; }
  ::i-block-chrome.fix-on-safari-5, .justify-content-center.fix-on-safari-5 {
    -webkit-box-pack: center; }
  ::i-block-chrome.fix-on-safari-5, .align-items-center.fix-on-safari-5 {
    -webkit-box-align: center;
    -ms-flex-align: center; }
  ::i-block-chrome, .form-check-inline-v4 {
    display: -webkit-inline-box; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 768px) {
  ::i-block-chrome, .form-check-inline-v4-md {
    display: -webkit-inline-box; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 992px) {
  ::i-block-chrome, .form-check-inline-v4-lg {
    display: -webkit-inline-box; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome a,
  ::i-block-chrome a:hover,
  ::i-block-chrome span,
  ::i-block-chrome span:hover, .pagination > li > a,
  .pagination > li > a:hover,
  .pagination > li > span,
  .pagination > li > span:hover {
    -webkit-text-stroke-width: .6px;
    -webkit-text-stroke-color: #002D5D; }
  ::i-block-chrome a,
  ::i-block-chrome a:hover,
  ::i-block-chrome a:focus,
  ::i-block-chrome span,
  ::i-block-chrome span:hover,
  ::i-block-chrome span:focus, .pagination > .active > a,
  .pagination > .active > a:hover,
  .pagination > .active > a:focus,
  .pagination > .active > span,
  .pagination > .active > span:hover,
  .pagination > .active > span:focus {
    -webkit-text-stroke-width: .6px;
    -webkit-text-stroke-color: #fff; }
  ::i-block-chrome a,
  ::i-block-chrome a:hover,
  ::i-block-chrome a:focus,
  ::i-block-chrome span,
  ::i-block-chrome span:hover,
  ::i-block-chrome span:focus, .pagination > .disabled > a,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > a:focus,
  .pagination > .disabled > span,
  .pagination > .disabled > span:hover,
  .pagination > .disabled > span:focus {
    -webkit-text-stroke-width: .6px;
    -webkit-text-stroke-color: #777; }
  ::i-block-chrome, .input-group-v4 {
    display: -webkit-box; }
    ::i-block-chrome .input-group-addon, .input-group-v4 .input-group-addon {
      display: -webkit-box; }
      ::i-block-chrome .input-group-addon .glyphicon, .input-group-v4 .input-group-addon .glyphicon {
        top: 3px; }
    ::i-block-chrome > .form-control, .input-group-v4 > .form-control {
      width: 89%; } }

html {
  height: 100%; }

body {
  font-family: "AcuminPro-Light", sans-serif !important;
  color: #808080;
  font-size: 14px;
  height: 100%;
  background: #002D5D; }

a {
  color: #FBAD18;
  text-decoration: none;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  a.color-primary {
    color: #808080; }
  a:hover, a:focus {
    color: #FBAD18; }

:focus {
  outline: none !important; }

.well {
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 20px 0;
  margin-bottom: 20px; }

.primary {
  color: #002D5D; }

.bold,
b,
strong {
  font-weight: normal;
  font-family: "AcuminPro-Semibold", sans-serif !important; }

_::-webkit-:not(:root:root), i {
  font-style: italic; }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome, i {
    font-weight: normal;
    font-family: "AcuminPro-Italic", sans-serif !important; } }

.content {
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 10px; }
  .content .title {
    color: #002D5D;
    font-family: "AcuminPro-Semibold", sans-serif !important;
    margin-top: 0; }

.border-bottom {
  border-bottom: 1px solid #000; }

h4, .h4 {
  font-family: 'AcuminPro-Medium'; }

h5, .h5 {
  font-size: 1.6rem;
  font-family: 'AcuminPro-Medium'; }

@media screen and (min-width: 768px) {
  h2,
  .h2 {
    font-size: 2.2rem; }
  h4,
  .h4 {
    font-size: 1.8rem; } }

@media screen and (max-width: 767px) {
  h2,
  .h2 {
    font-size: 1.8rem; }
  h4,
  .h4 {
    font-size: 1.5rem; } }

.question-content .radio {
  margin-bottom: 15px;
  margin-top: 15px; }

.question-content label {
  min-width: 50px;
  line-height: 20px; }

.progress {
  margin-top: 40px; }

.jump-to {
  display: inline-block;
  margin: 20px 10px 20px 40px;
  vertical-align: top;
  line-height: 33px; }
  @media screen and (max-width: 767px) {
    .jump-to {
      margin: 0 10px 0 0; }
      .jump-to + .form-group {
        margin-top: 0 !important; } }
  .jump-to + .form-group {
    display: inline-block;
    margin-top: 20px;
    vertical-align: top; }

#patient-import-loader {
  pointer-events: all;
  z-index: 99999;
  border: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  cursor: wait;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.6); }

.border {
  border: 1px solid #808080;
  display: flex;
  flex-wrap: wrap; }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome, .border {
    display: table; }
    ::i-block-chrome .col-v4-md-6, .border .col-v4-md-6 {
      float: left; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 768px) {
      ::i-block-chrome .col-v4-md-6, .border .col-v4-md-6 {
        width: 50%;
        max-width: 50% !important; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
    ::i-block-chrome .col-v4-lg-4,
    ::i-block-chrome .col-v4-md, .border .col-v4-lg-4,
    .border .col-v4-md {
      float: left; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 992px) {
      ::i-block-chrome .col-v4-lg-4,
      ::i-block-chrome .col-v4-md, .border .col-v4-lg-4,
      .border .col-v4-md {
        width: 33.33333%;
        max-width: 33.33333% !important; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome, .glyphicon {
    font-family: 'Glyphicons Halflings' !important; } }

.pr-0 {
  padding-right: 0 !important; }

.border-top {
  border-top: 1px solid #808080; }

@media screen and (min-width: 992px) {
  .border-right-lg {
    border-right: 1px solid #808080; }
  .border-top-lg-0 {
    border-top: 0 !important; } }

@media screen and (min-width: 768px) {
  .border-right-md {
    border-right: 1px solid #808080; }
  .border-top-md-0 {
    border-top: 0 !important; } }

.img-responsive:focus {
  background-color: #fff; }

.progress-bar {
  background-color: #002D5D; }

.text-warning {
  color: #FBAD18;
  font-family: "AcuminPro-Medium", sans-serif !important; }

.text-justify {
  text-align: justify; }

.text-large {
  font-size: large; }

.m-auto {
  margin: auto; }

.text-exception {
  border-bottom: dotted;
  padding-top: 10px; }

.mb-0 {
  margin-bottom: 0px !important; }

.no-resize {
  resize: none; }

.ql-snow .ql-tooltip {
  left: 0 !important; }

.scoringMessages > thead > tr > th:not(:last-child) {
  width: 23%; }

.scoringMessages > tbody > tr > td {
  vertical-align: top !important; }

.scoringMessages .w-55 {
  width: 55% !important; }

.question-option > thead > tr > th:not(:last-child) {
  width: 48%; }

.question-option > tbody > tr > td {
  vertical-align: top !important; }

.optionQuestion > tbody > tr > td {
  vertical-align: top !important; }

/* [Start] IPMproject-B1052: HAQ workflow: The content of instructions is not displayed as configuration on test editor */
.ql-align-right {
  text-align: right; }

.ql-font-monospace {
  font-family: Monaco, Courier New, monospace; }

.ql-font-serif {
  font-family: Georgia, Times New Roman, serif; }

.ql-align-justify {
  text-align: justify; }

.ql-align-center {
  text-align: center; }

.ql-size-small {
  font-size: 0.75em; }

.ql-size-large {
  font-size: 1.5em; }

.ql-size-huge {
  font-size: 2.5em; }

/* [Start] IPMproject-B1052: HAQ workflow: The content of instructions is not displayed as configuration on test editor */
.courseEditor a {
  font-weight: bold !important;
  color: #002D5D !important;
  text-decoration: underline; }

.courseEditor > thead > tr > th:not(:last-child) {
  width: 23%; }

.courseEditor > thead > tr > th {
  border: none; }

.courseEditor > tbody > tr > td {
  vertical-align: top !important;
  border: none; }

.courseEditor > tfoot > tr > td {
  border: none; }

.courseEditor .haqIndicator {
  margin-top: 8px; }

.courseEditor .haqTest {
  width: 450px !important; }

.courseEditor .threshold {
  width: 110px !important;
  padding-left: 16px; }

.courseEditor .threshold-value {
  width: 100px; }

.courseEditor .recommendationType {
  width: 200px; }

.courseEditor .time-of-day {
  display: inline !important;
  width: 76px; }

.mde-report-pt a {
  font-weight: bold !important;
  color: #002D5D !important;
  text-decoration: underline; }

.link-danger {
  color: #a94442 !important; }

.navbar.navbar-default {
  background-color: #fff;
  color: #fff;
  border: 0;
  border-radius: 0; }
  .navbar.navbar-default .container {
    position: relative; }
    .navbar.navbar-default .container ul {
      padding: 0;
      margin: 0; }
      .navbar.navbar-default .container ul li {
        display: inline-flex;
        border: 1px solid #dfdfdf;
        border-radius: 22px;
        -moz-border-radius: 22px;
        -webkit-border-radius: 22px;
        padding: 7px 15px;
        font-weight: bold;
        margin-left: 0.5rem;
        align-items: center;
        list-style: none; }
        .navbar.navbar-default .container ul li:first-child {
          margin-left: 0; }
        .navbar.navbar-default .container ul li a {
          color: #0D9C93;
          font-weight: normal;
          font-family: "AcuminPro-Semibold", sans-serif !important;
          white-space: nowrap; }
          .navbar.navbar-default .container ul li a:hover, .navbar.navbar-default .container ul li a:focus, .navbar.navbar-default .container ul li a:active, .navbar.navbar-default .container ul li a.active {
            text-decoration: none;
            color: #002D5D; }
        .navbar.navbar-default .container ul li.hello {
          border: 1px solid #0D9C93;
          background-color: #0D9C93; }
          @media screen and (max-width: 767px) {
            .navbar.navbar-default .container ul li.hello {
              max-width: calc(100vw - 240px); } }
          @media screen and (max-width: 1199px) {
            .navbar.navbar-default .container ul li.hello.haveAccount {
              max-width: calc(100vw - 800px); } }
          @media screen and (max-width: 1023px) {
            .navbar.navbar-default .container ul li.hello.haveAccount {
              max-width: calc(100vw - 210px); } }
          @media screen and (max-width: 767px) {
            .navbar.navbar-default .container ul li.hello.haveAccount {
              max-width: calc(100vw - 150px); } }
          @media screen and (min-width: 1200px) {
            .navbar.navbar-default .container ul li.hello.haveAccount {
              max-width: 330px; } }
          @media screen and (max-width: 1199px) {
            .navbar.navbar-default .container ul li.hello.haveAccount-Patient {
              max-width: calc(100vw - 340px); } }
          @media screen and (max-width: 767px) {
            .navbar.navbar-default .container ul li.hello.haveAccount-Patient {
              max-width: calc(100vw - 271px); } }
          @media screen and (min-width: 1200px) {
            .navbar.navbar-default .container ul li.hello.haveAccount-Patient {
              max-width: 860px; } }
          .navbar.navbar-default .container ul li.hello a {
            color: #fff;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          .navbar.navbar-default .container ul li.hello ul li a {
            color: #0D9C93; }
        .navbar.navbar-default .container ul li ul {
          margin-top: 5px;
          min-width: 120px; }
          .navbar.navbar-default .container ul li ul li {
            display: block;
            border: 0;
            border-bottom: 1px solid #dfdfdf;
            border-radius: 0;
            margin-right: 0;
            margin-left: 0;
            width: 100%; }
            .navbar.navbar-default .container ul li ul li:hover, .navbar.navbar-default .container ul li ul li:focus {
              background-color: rgba(0, 0, 0, 0.05); }
            .navbar.navbar-default .container ul li ul li a {
              padding-left: 0;
              padding-right: 0; }
              .navbar.navbar-default .container ul li ul li a:hover, .navbar.navbar-default .container ul li ul li a:focus, .navbar.navbar-default .container ul li ul li a:active, .navbar.navbar-default .container ul li ul li a.active {
                color: #002D5D;
                background-color: transparent; }
            @media (min-width: 768px) {
              .navbar.navbar-default .container ul li ul li.d-md-none-special {
                display: none; } }
          .navbar.navbar-default .container ul li ul > .active > a, .navbar.navbar-default .container ul li ul > .active > a:hover, .navbar.navbar-default .container ul li ul > .active > a:focus {
            color: #002D5D;
            background-color: transparent; }
        .navbar.navbar-default .container ul li.d-none-special {
          display: none; }
        @media (min-width: 992px) {
          .navbar.navbar-default .container ul li.d-lg-inline-flex-special {
            display: inline-flex; } }

.navbar .navbar-brand {
  height: 7vh;
  padding: 0;
  margin-left: 0 !important;
  min-height: 53px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome, .navbar-flex {
    display: -webkit-box !important;
    -webkit-box-align: center !important;
    -webkit-box-pack: justify !important; }
  ::i-block-chrome, .navbar.navbar-default .container ul li {
    display: -webkit-inline-box !important; }
  ::i-block-chrome, .navbar.navbar-default .container ul li.hidden {
    display: none !important; }
  ::i-block-chrome, .flex-hello {
    display: -webkit-box !important; } }
  @media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 992px) {
    ::i-block-chrome, .flex-hello {
      display: -webkit-inline-box !important; } }

.form-group .form-control {
  border: 1px solid #dcdcdc;
  color: #808080;
  display: block;
  padding: 6px 12px !important;
  transition: all 0.1s ease-in;
  box-shadow: none;
  height: 34px; }
  .form-group .form-control.ng-dirty.ng-invalid {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.form-group select.form-control {
  padding: 6px 0 6px 6px !important;
  line-height: 30px; }
  .form-group select.form-control#surveyStatus {
    min-width: 140px; }

.form-inline .form-control.ng-dirty.ng-invalid {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.form-control {
  font-size: 13px; }

.label-info {
  background-color: rgba(0, 0, 0, 0.05);
  color: #808080;
  font-size: 14px;
  font-family: "AcuminPro-Medium", sans-serif !important;
  font-weight: normal; }

::-webkit-input-placeholder {
  /* Chrome, Safari */
  color: #808080 !important; }

:-moz-placeholder {
  /* Firefox 18- */
  color: #808080 !important; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: #808080 !important; }

:-ms-input-placeholder {
  /* Internet Explorer */
  color: #808080 !important; }

input:-webkit-autofill {
  background-color: #fff !important;
  color: #808080 !important; }

::selection {
  background: #002D5D;
  color: #fff;
  text-shadow: none; }

.frm-login {
  background-color: #fff;
  padding: 0;
  border-radius: 10px;
  display: inline-block;
  position: relative;
  width: 105%; }
  .frm-login input {
    padding: 0 10px !important; }
  .frm-login .frm-login-top {
    padding: 20px 21px 25px 21px; }
    .frm-login .frm-login-top .h4 {
      padding-bottom: 20px; }
    .frm-login .frm-login-top .form-group {
      margin-bottom: 25px; }
      .frm-login .frm-login-top .form-group .forget-password {
        position: absolute;
        right: 10px;
        top: 8px;
        z-index: 9;
        font-style: italic;
        color: #808080;
        font-size: 12px; }
        .frm-login .frm-login-top .form-group .forget-password:focus {
          outline: none; }
  .frm-login .frm-login-bottom {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 20px 21px 30px 21px;
    border-radius: 0 0 9px 9px;
    display: inline-block;
    width: 100%; }
    .frm-login .frm-login-bottom button {
      margin-top: 30px;
      height: auto; }

.icon-required {
  position: relative;
  top: -4px;
  color: #d9534f;
  font-size: 7px; }

h3 .icon-required {
  top: -10px; }

h4 .icon-required {
  top: -10px; }

.help-block {
  color: #a94442; }

.has-feedback-left .form-control-feedback {
  left: 11px; }

.has-feedback-left .search-box {
  padding-left: 30px !important; }

.glyphicon:focus {
  outline: none; }

.input-group-addon {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.7); }
  .input-group-addon .disabled {
    cursor: not-allowed; }

.date-time-drop-down select.form-control {
  width: 100%; }

select.ng-empty {
  color: #808080; }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

label {
  font-weight: normal;
  position: relative; }

textarea.form-control {
  height: auto; }

textarea.auto-height {
  height: auto !important; }

textarea.none-resize {
  resize: none !important; }

.checkbox-group {
  position: relative; }
  .checkbox-group .custom-control {
    position: absolute;
    width: 4rem;
    height: 4rem; }
    .checkbox-group .custom-control .custom-control-input {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 4rem;
      height: 4rem;
      opacity: 0;
      margin: 0;
      top: 0;
      left: 0; }
      .checkbox-group .custom-control .custom-control-input:checked ~ .custom-control-label::before {
        border-color: #00aff0; }
      .checkbox-group .custom-control .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("../../content/images/check.svg"); }
    .checkbox-group .custom-control.custom-checkbox .custom-control-label {
      position: relative; }
      .checkbox-group .custom-control.custom-checkbox .custom-control-label::before {
        transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        border-radius: 0.25rem;
        position: absolute;
        top: -1rem;
        left: 0;
        display: block;
        width: 4rem;
        height: 4rem;
        pointer-events: none;
        content: "";
        background-color: transparent;
        border: #808080 solid 1px; }
      .checkbox-group .custom-control.custom-checkbox .custom-control-label::after {
        position: absolute;
        top: -1rem;
        left: 0;
        display: block;
        width: 4rem;
        height: 4rem;
        content: "";
        background: no-repeat 50%/50% 50%; }
    .checkbox-group .custom-control.front-1 {
      left: calc(50% - 2rem); }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-1 {
          top: 8%; } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-1 {
          top: 6.5%; } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-1 {
          top: 7.5%; } }
    .checkbox-group .custom-control.back-1 {
      left: calc(50% - 2rem); }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.back-1 {
          top: 6%; } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.back-1 {
          top: 4.5%; } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.back-1 {
          top: 5.5%; } }
    .checkbox-group .custom-control.front-2, .checkbox-group .custom-control.back-2 {
      top: 18%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-2, .checkbox-group .custom-control.back-2 {
          left: calc(50% - 9rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-2, .checkbox-group .custom-control.back-2 {
          left: calc(50% - 7rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-2, .checkbox-group .custom-control.back-2 {
          left: calc(50% - 8rem); } }
    .checkbox-group .custom-control.front-3, .checkbox-group .custom-control.back-3 {
      top: 15.5%;
      left: calc(50% - 2rem); }
    .checkbox-group .custom-control.front-4, .checkbox-group .custom-control.back-4 {
      top: 18%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-4, .checkbox-group .custom-control.back-4 {
          left: calc(50% + 5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-4, .checkbox-group .custom-control.back-4 {
          left: calc(50% + 3rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-4, .checkbox-group .custom-control.back-4 {
          left: calc(50% + 4rem); } }
    .checkbox-group .custom-control.front-5, .checkbox-group .custom-control.back-5 {
      top: 25%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-5, .checkbox-group .custom-control.back-5 {
          left: calc(50% - 11rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-5, .checkbox-group .custom-control.back-5 {
          left: calc(50% - 10.5rem); } }
    .checkbox-group .custom-control.front-6, .checkbox-group .custom-control.back-6 {
      top: 25%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-6, .checkbox-group .custom-control.back-6 {
          left: calc(50% - 6.5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-6, .checkbox-group .custom-control.back-6 {
          left: calc(50% - 6.2rem); } }
    .checkbox-group .custom-control.front-7, .checkbox-group .custom-control.back-7 {
      top: 23%;
      left: calc(50% - 2rem); }
    .checkbox-group .custom-control.front-8, .checkbox-group .custom-control.back-8 {
      top: 25%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-8, .checkbox-group .custom-control.back-8 {
          left: calc(50% + 2.5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-8, .checkbox-group .custom-control.back-8 {
          left: calc(50% + 2.2rem); } }
    .checkbox-group .custom-control.front-9, .checkbox-group .custom-control.back-9 {
      top: 25%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-9, .checkbox-group .custom-control.back-9 {
          left: calc(50% + 7rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-9, .checkbox-group .custom-control.back-9 {
          left: calc(50% + 6.5rem); } }
    .checkbox-group .custom-control.front-10, .checkbox-group .custom-control.back-10 {
      top: 32%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-10, .checkbox-group .custom-control.back-10 {
          left: calc(50% - 11.5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-10, .checkbox-group .custom-control.back-10 {
          left: calc(50% - 10.5rem); } }
    .checkbox-group .custom-control.front-11, .checkbox-group .custom-control.back-11 {
      top: 33.5%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-11, .checkbox-group .custom-control.back-11 {
          left: calc(50% - 6.5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-11, .checkbox-group .custom-control.back-11 {
          left: calc(50% - 6.2rem); } }
    .checkbox-group .custom-control.front-12, .checkbox-group .custom-control.back-12 {
      top: 32%;
      left: calc(50% - 2rem); }
    .checkbox-group .custom-control.front-13, .checkbox-group .custom-control.back-13 {
      top: 33.5%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-13, .checkbox-group .custom-control.back-13 {
          left: calc(50% + 2.5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-13, .checkbox-group .custom-control.back-13 {
          left: calc(50% + 2.2rem); } }
    .checkbox-group .custom-control.front-14, .checkbox-group .custom-control.back-14 {
      top: 32%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-14, .checkbox-group .custom-control.back-14 {
          left: calc(50% + 7.5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-14, .checkbox-group .custom-control.back-14 {
          left: calc(50% + 6.5rem); } }
    .checkbox-group .custom-control.front-15, .checkbox-group .custom-control.back-15 {
      top: 39%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-15, .checkbox-group .custom-control.back-15 {
          left: calc(50% - 12rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-15, .checkbox-group .custom-control.back-15 {
          left: calc(50% - 10.5rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-15, .checkbox-group .custom-control.back-15 {
          left: calc(50% - 11.5rem); } }
      @media screen and (max-width: 767px) {
        .checkbox-group .custom-control.front-15, .checkbox-group .custom-control.back-15 {
          left: calc(50% - 10.5rem); } }
    .checkbox-group .custom-control.front-16, .checkbox-group .custom-control.back-16 {
      top: 41%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-16, .checkbox-group .custom-control.back-16 {
          left: calc(50% - 7rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-16, .checkbox-group .custom-control.back-16 {
          left: calc(50% - 6.2rem); } }
    .checkbox-group .custom-control.front-17, .checkbox-group .custom-control.back-17 {
      top: 40%;
      left: calc(50% - 2rem); }
    .checkbox-group .custom-control.front-18, .checkbox-group .custom-control.back-18 {
      top: 41%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-18, .checkbox-group .custom-control.back-18 {
          left: calc(50% + 3rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-18, .checkbox-group .custom-control.back-18 {
          left: calc(50% + 2.2rem); } }
    .checkbox-group .custom-control.front-19, .checkbox-group .custom-control.back-19 {
      top: 39%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-19, .checkbox-group .custom-control.back-19 {
          left: calc(50% + 8rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-19, .checkbox-group .custom-control.back-19 {
          left: calc(50% + 6.5rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-19, .checkbox-group .custom-control.back-19 {
          left: calc(50% + 7.5rem); } }
      @media screen and (max-width: 767px) {
        .checkbox-group .custom-control.front-19, .checkbox-group .custom-control.back-19 {
          left: calc(50% + 6.5rem); } }
    .checkbox-group .custom-control.front-20, .checkbox-group .custom-control.back-20 {
      top: 45.8%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-20, .checkbox-group .custom-control.back-20 {
          left: calc(50% - 13rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-20, .checkbox-group .custom-control.back-20 {
          left: calc(50% - 10.3rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-20, .checkbox-group .custom-control.back-20 {
          left: calc(50% - 12rem); } }
    @media screen and (min-width: 1280px) {
      .checkbox-group .custom-control.front-21, .checkbox-group .custom-control.back-21 {
        top: 47%;
        left: calc(50% - 17.5rem); } }
    @media screen and (max-width: 1279px) {
      .checkbox-group .custom-control.front-21, .checkbox-group .custom-control.back-21 {
        top: 45.8%;
        left: calc(50% - 14.5rem); } }
    @media screen and (max-width: 991px) {
      .checkbox-group .custom-control.front-21, .checkbox-group .custom-control.back-21 {
        top: 46.5%;
        left: calc(50% - 16.3rem); } }
    .checkbox-group .custom-control.front-22, .checkbox-group .custom-control.back-22 {
      top: 53%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-22, .checkbox-group .custom-control.back-22 {
          left: calc(50% - 14.5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-22, .checkbox-group .custom-control.back-22 {
          left: calc(50% - 12rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-22, .checkbox-group .custom-control.back-22 {
          left: calc(50% - 13.5rem); } }
      @media screen and (max-width: 767px) {
        .checkbox-group .custom-control.front-22, .checkbox-group .custom-control.back-22 {
          left: calc(50% - 12.5rem); } }
    .checkbox-group .custom-control.front-23, .checkbox-group .custom-control.back-23 {
      top: 48%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-23, .checkbox-group .custom-control.back-23 {
          left: calc(50% - 8rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-23, .checkbox-group .custom-control.back-23 {
          left: calc(50% - 6rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-23, .checkbox-group .custom-control.back-23 {
          left: calc(50% - 7rem); } }
    .checkbox-group .custom-control.front-24, .checkbox-group .custom-control.back-24 {
      top: 47%;
      left: calc(50% - 2rem); }
    .checkbox-group .custom-control.front-25, .checkbox-group .custom-control.back-25 {
      top: 48%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-25, .checkbox-group .custom-control.back-25 {
          left: calc(50% + 4rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-25, .checkbox-group .custom-control.back-25 {
          left: calc(50% + 2rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-25, .checkbox-group .custom-control.back-25 {
          left: calc(50% + 3rem); } }
    .checkbox-group .custom-control.front-26, .checkbox-group .custom-control.back-26 {
      top: 45.8%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-26, .checkbox-group .custom-control.back-26 {
          left: calc(50% + 9rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-26, .checkbox-group .custom-control.back-26 {
          left: calc(50% + 6.3rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-26, .checkbox-group .custom-control.back-26 {
          left: calc(50% + 8rem); } }
    @media screen and (min-width: 1280px) {
      .checkbox-group .custom-control.front-27, .checkbox-group .custom-control.back-27 {
        top: 47%;
        left: calc(50% + 13.5rem); } }
    @media screen and (max-width: 1279px) {
      .checkbox-group .custom-control.front-27, .checkbox-group .custom-control.back-27 {
        top: 45.8%;
        left: calc(50% + 10.5rem); } }
    @media screen and (max-width: 991px) {
      .checkbox-group .custom-control.front-27, .checkbox-group .custom-control.back-27 {
        top: 46.5%;
        left: calc(50% + 12.3rem); } }
    .checkbox-group .custom-control.front-28, .checkbox-group .custom-control.back-28 {
      top: 53%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-28, .checkbox-group .custom-control.back-28 {
          left: calc(50% + 10.5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-28, .checkbox-group .custom-control.back-28 {
          left: calc(50% + 8rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-28, .checkbox-group .custom-control.back-28 {
          left: calc(50% + 9.5rem); } }
      @media screen and (max-width: 767px) {
        .checkbox-group .custom-control.front-28, .checkbox-group .custom-control.back-28 {
          left: calc(50% + 8.5rem); } }
    .checkbox-group .custom-control.front-29, .checkbox-group .custom-control.back-29 {
      top: 58%;
      left: calc(50% - 7rem); }
    .checkbox-group .custom-control.front-30, .checkbox-group .custom-control.back-30 {
      top: 56%;
      left: calc(50% - 2rem); }
    .checkbox-group .custom-control.front-31, .checkbox-group .custom-control.back-31 {
      top: 58%;
      left: calc(50% + 3rem); }
    .checkbox-group .custom-control.front-32, .checkbox-group .custom-control.back-32 {
      top: 69%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-32, .checkbox-group .custom-control.back-32 {
          left: calc(50% - 5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-32, .checkbox-group .custom-control.back-32 {
          left: calc(50% - 4.5rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-32, .checkbox-group .custom-control.back-32 {
          left: calc(50% - 5rem); } }
    .checkbox-group .custom-control.front-33, .checkbox-group .custom-control.back-33 {
      top: 69%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-33, .checkbox-group .custom-control.back-33 {
          left: calc(50% + 1rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-33, .checkbox-group .custom-control.back-33 {
          left: calc(50% + 0.5rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-33, .checkbox-group .custom-control.back-33 {
          left: calc(50% + 1rem); } }
    .checkbox-group .custom-control.front-34, .checkbox-group .custom-control.back-34 {
      top: 79%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-34, .checkbox-group .custom-control.back-34 {
          left: calc(50% - 5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-34, .checkbox-group .custom-control.back-34 {
          left: calc(50% - 4.5rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-34, .checkbox-group .custom-control.back-34 {
          left: calc(50% - 5rem); } }
      @media screen and (max-width: 767px) {
        .checkbox-group .custom-control.front-34, .checkbox-group .custom-control.back-34 {
          left: calc(50% - 4.5rem); } }
    .checkbox-group .custom-control.front-35, .checkbox-group .custom-control.back-35 {
      top: 79%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-35, .checkbox-group .custom-control.back-35 {
          left: calc(50% + 1rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-35, .checkbox-group .custom-control.back-35 {
          left: calc(50% + 0.5rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-35, .checkbox-group .custom-control.back-35 {
          left: calc(50% + 1rem); } }
      @media screen and (max-width: 767px) {
        .checkbox-group .custom-control.front-35, .checkbox-group .custom-control.back-35 {
          left: calc(50% + 0.5rem); } }
    .checkbox-group .custom-control.front-36, .checkbox-group .custom-control.back-36 {
      top: 86%;
      left: calc(50% - 4.5rem); }
    .checkbox-group .custom-control.front-37, .checkbox-group .custom-control.back-37 {
      top: 86%;
      left: calc(50% + 0.5rem); }
    .checkbox-group .custom-control.front-38, .checkbox-group .custom-control.back-38 {
      top: 93%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-38, .checkbox-group .custom-control.back-38 {
          left: calc(50% - 5.5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-38, .checkbox-group .custom-control.back-38 {
          left: calc(50% - 4.5rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-38, .checkbox-group .custom-control.back-38 {
          left: calc(50% - 5rem); } }
    .checkbox-group .custom-control.front-39, .checkbox-group .custom-control.back-39 {
      top: 93%; }
      @media screen and (min-width: 1280px) {
        .checkbox-group .custom-control.front-39, .checkbox-group .custom-control.back-39 {
          left: calc(50% + 1.5rem); } }
      @media screen and (max-width: 1279px) {
        .checkbox-group .custom-control.front-39, .checkbox-group .custom-control.back-39 {
          left: calc(50% + 1rem); } }
      @media screen and (max-width: 991px) {
        .checkbox-group .custom-control.front-39, .checkbox-group .custom-control.back-39 {
          left: calc(50% + 0.5rem); } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) {
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-1, .checkbox-group .custom-control.back-1, .checkbox-group .custom-control.front-3, .checkbox-group .custom-control.back-3, .checkbox-group .custom-control.front-7, .checkbox-group .custom-control.back-7, .checkbox-group .custom-control.front-12, .checkbox-group .custom-control.back-12, .checkbox-group .custom-control.front-17, .checkbox-group .custom-control.back-17, .checkbox-group .custom-control.front-24, .checkbox-group .custom-control.back-24, .checkbox-group .custom-control.front-30, .checkbox-group .custom-control.back-30 {
        left: 44%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-2, .checkbox-group .custom-control.back-2 {
    left: 24%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-2, .checkbox-group .custom-control.back-2 {
    left: 26%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 991px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-2, .checkbox-group .custom-control.back-2 {
    left: 25%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-4, .checkbox-group .custom-control.back-4 {
    left: 64%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-4, .checkbox-group .custom-control.back-4 {
    left: 66%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 991px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-4, .checkbox-group .custom-control.back-4 {
    left: 65%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-5, .checkbox-group .custom-control.back-5 {
    left: 18%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-5, .checkbox-group .custom-control.back-5 {
    left: 17.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-6, .checkbox-group .custom-control.back-6 {
    left: 31%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-6, .checkbox-group .custom-control.back-6 {
    left: 30.8%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-8, .checkbox-group .custom-control.back-8 {
    left: 58%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-8, .checkbox-group .custom-control.back-8 {
    left: 57.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-9, .checkbox-group .custom-control.back-9 {
    left: 70.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-9, .checkbox-group .custom-control.back-9 {
    left: 70%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-10, .checkbox-group .custom-control.back-10 {
    left: 17%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-10, .checkbox-group .custom-control.back-10 {
    left: 16%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-11, .checkbox-group .custom-control.back-11 {
    left: 31.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-11, .checkbox-group .custom-control.back-11 {
    left: 31%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-13, .checkbox-group .custom-control.back-13 {
    left: 57%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-13, .checkbox-group .custom-control.back-13 {
    left: 56.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-14, .checkbox-group .custom-control.back-14 {
    left: 72%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-14, .checkbox-group .custom-control.back-14 {
    left: 71%; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) {
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-15, .checkbox-group .custom-control.back-15 {
        left: 15%; }
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-16, .checkbox-group .custom-control.back-16 {
        left: 30%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-18, .checkbox-group .custom-control.back-18 {
    left: 59%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-18, .checkbox-group .custom-control.back-18 {
    left: 57.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-19, .checkbox-group .custom-control.back-19 {
    left: 73.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-19, .checkbox-group .custom-control.back-19 {
    left: 72.5%; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) {
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-20, .checkbox-group .custom-control.back-20 {
        left: 13%; }
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-21, .checkbox-group .custom-control.back-21 {
        left: 0; }
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-22, .checkbox-group .custom-control.back-22 {
        left: 8%; }
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-23, .checkbox-group .custom-control.back-23 {
        left: 27%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-25, .checkbox-group .custom-control.back-25 {
    left: 61.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-25, .checkbox-group .custom-control.back-25 {
    left: 60.5%; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) {
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-26, .checkbox-group .custom-control.back-26 {
        left: 75.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-27, .checkbox-group .custom-control.back-27 {
    left: 88%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-27, .checkbox-group .custom-control.back-27 {
    left: 88.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-28, .checkbox-group .custom-control.back-28 {
    left: 80.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-28, .checkbox-group .custom-control.back-28 {
    left: 79.5%; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) {
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-29, .checkbox-group .custom-control.back-29 {
        left: 30%; }
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-31, .checkbox-group .custom-control.back-31 {
        left: 58%; }
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-32, .checkbox-group .custom-control.back-32 {
        left: 35%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-33, .checkbox-group .custom-control.back-33 {
    left: 54%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-33, .checkbox-group .custom-control.back-33 {
    left: 52%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-34, .checkbox-group .custom-control.back-34 {
    left: 36%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-34, .checkbox-group .custom-control.back-34 {
    left: 35%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-35, .checkbox-group .custom-control.back-35 {
    left: 53%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-35, .checkbox-group .custom-control.back-35 {
    left: 52%; } }
    @media only screen and (-webkit-min-device-pixel-ratio: 1) {
      .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-36, .checkbox-group .custom-control.back-36 {
        left: 37%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-37, .checkbox-group .custom-control.back-37 {
    left: 51.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-37, .checkbox-group .custom-control.back-37 {
    left: 50.5%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-38, .checkbox-group .custom-control.back-38 {
    left: 36%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-38, .checkbox-group .custom-control.back-38 {
    left: 35%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (min-width: 1280px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-39, .checkbox-group .custom-control.back-39 {
    left: 53%; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) and (max-width: 1279px) {
  .checkbox-group .custom-control ::i-block-chrome, .checkbox-group .custom-control.front-39, .checkbox-group .custom-control.back-39 {
    left: 52%; } }
  @media screen and (max-width: 736px) {
    .checkbox-group .img-responsive {
      width: 291px;
      margin: auto; } }
  @media screen and (max-width: 375px) {
    .checkbox-group .img-responsive {
      width: auto; } }

@media screen and (min-width: 768px) {
  .form-inline label {
    padding-left: 10px; }
    .form-inline label:first-child {
      padding-left: 0; } }

@media screen and (min-width: 768px) {
  .form-inline .checkbox {
    margin-top: -5px; } }

.form-inline .checkbox + .checkbox {
  margin-left: 10px; }

.form-inline .checkbox label input[type="checkbox"] {
  margin-right: 4px;
  position: relative;
  top: 1px; }

@media screen and (min-width: 768px) {
  .form-inline .form-control {
    margin-left: 5px; } }

.form-inline .form-control-static {
  padding-top: 5px;
  vertical-align: middle; }

@media screen and (max-width: 767px) {
  .radio-xs {
    display: block !important; }
    .radio-xs + .radio-xs {
      margin-left: 0; } }

@media screen and (max-width: 767px) {
  .checkbox-xs {
    display: block !important; }
    .checkbox-xs + .checkbox-xs {
      margin-left: 0; } }

@media screen and (min-width: 1280px) {
  input[type="radio"] {
    margin: -1px 0.3125rem 0 0;
    position: relative;
    top: 2px; } }

@media screen and (max-width: 1279px) {
  input[type="radio"] {
    margin: 1px 0.3125rem 0 0; } }

input[type="checkbox"] {
  margin: 0 0.3125rem 0 0; }
  @media screen and (min-width: 1280px) {
    input[type="checkbox"] {
      position: relative;
      top: 1px; } }

@media screen and (max-width: 767px) {
  .radio-sm {
    display: block; }
    .radio-sm + .radio-sm {
      margin-left: 0 !important; } }

.checkbox-inline label {
  padding-left: 0; }

ol li {
  padding-left: 10px; }
  ol li + li {
    padding-top: 10px; }
  @media screen and (min-width: 768px) {
    ol li .form-inline {
      margin: 0; } }

.pdl-14 {
  padding-left: 14px; }

.radio-inline {
  margin-right: 10px; }
  .radio-inline + .radio-inline {
    margin-left: 0; }

.input-group-btn .btn {
  height: 34px; }

.mac .navbar.navbar-default .container ul li a {
  position: relative;
  top: 2px; }

.min-height-115 {
  min-height: 115px; }

.quill-select-disabled {
  pointer-events: none;
  opacity: 0.5; }

.disabled-background-color {
  background-color: #eeeeee; }

.form-group.typeahead {
  position: relative;
  z-index: 6; }
  .form-group.typeahead .dropdown-menu {
    width: 100%;
    max-height: 200px;
    overflow-y: auto; }
  .form-group.typeahead .input-group {
    z-index: 2; }
  .form-group.typeahead .ipm-auto-complete-container {
    position: relative;
    top: -3px;
    z-index: 1; }
    .form-group.typeahead .ipm-auto-complete-container .imp-auto-complete-box {
      overflow: auto;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      border: 1px solid rgba(0, 0, 0, 0.15);
      margin-top: -1px;
      border-radius: 4px;
      -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      background-color: #fff;
      z-index: 100; }
      .form-group.typeahead .ipm-auto-complete-container .imp-auto-complete-box .dropdown-menu[uib-typeahead-popup] {
        max-height: none;
        margin-left: -39px;
        margin: 5px 0;
        background-color: transparent;
        border-width: 0;
        box-shadow: none;
        position: static;
        min-width: 100%;
        padding: 0;
        border-radius: 0; }

.btn {
  font-size: 14px;
  border-radius: 3px;
  transition: background-color .2s;
  -webkit-transition: background-color .2s;
  -o-transition: background-color .2s;
  -moz-transition: background-color .2s;
  -ms-transition: background-color .2s; }
  .btn.btn-primary, .btn.btn-info, .btn.btn-success {
    background-color: #0D9C93;
    border-color: #0D9C93;
    color: #fff; }
    .btn.btn-primary:hover, .btn.btn-info:hover, .btn.btn-success:hover {
      background-color: #0D9C93;
      border-color: #0D9C93; }
  .btn.btn-warning, .btn.btn-warning:active:focus {
    background-color: #FBAD18;
    border-color: #FBAD18;
    color: #1A1A1A;
    font-family: 'AcuminPro-Cond-Bold';
    font-size: 3rem;
    border-radius: 2rem;
    padding: 3px 20px 5px 20px;
    line-height: 1; }
    @media screen and (max-width: 1024px) {
      .btn.btn-warning, .btn.btn-warning:active:focus {
        padding: 5px 20px 0 20px; } }
  .btn.btn-default {
    color: rgba(0, 0, 0, 0.7);
    background-color: #fff;
    border-color: #ccc;
    text-shadow: none; }
    .btn.btn-default:hover {
      background-color: rgba(0, 0, 0, 0.05); }
    .btn.btn-default.btn-toggle {
      position: relative;
      width: 50px;
      height: 27px;
      padding: 0;
      text-indent: -99999px;
      border: 1px solid #cfcfcf;
      border-radius: 15px;
      outline: none;
      background: #cfcfcf;
      box-shadow: none; }
      .btn.btn-default.btn-toggle:before {
        position: absolute;
        top: 1px;
        left: 1px;
        display: inline-block;
        width: 24px;
        height: 24px;
        content: "";
        border-radius: 50%;
        background: #fff;
        -webkit-box-shadow: 0 0px 9px rgba(0, 0, 0, 0.2);
        box-shadow: 0 0px 9px rgba(0, 0, 0, 0.2); }
      .btn.btn-default.btn-toggle.active {
        border: #0D9C93;
        background: #0D9C93; }
        .btn.btn-default.btn-toggle.active:before {
          right: 1px;
          left: inherit;
          top: 2px; }
        .btn.btn-default.btn-toggle.active.disable {
          pointer-events: none; }
      .btn.btn-default.btn-toggle.disable {
        pointer-events: none; }
  .btn.btn-sm {
    padding: 5px 10px;
    font-size: 11px; }

.btn-transparent {
  border-width: 0;
  background-color: transparent;
  padding: 6px 0; }

@media screen and (max-width: 767px) {
  .pagination {
    width: 240px;
    margin: 20px auto;
    min-height: 33px; } }

.pagination > li > a, .pagination > li > a:hover,
.pagination > li > span,
.pagination > li > span:hover {
  color: #0D9C93; }

.pagination > .active > a,
.pagination > .active > a:hover,
.pagination > .active > a:focus,
.pagination > .active > span,
.pagination > .active > span:hover,
.pagination > .active > span:focus {
  background-color: #002D5D;
  border-color: #002D5D;
  color: #fff; }

.btn-step .btn-step-child .btn-arrow-text {
  position: relative;
  top: -3px; }

.btn-step .btn-step-child {
  display: inline-block;
  padding: 20px 0;
  margin-right: 30px; }
  .btn-step .btn-step-child.btn-next {
    display: inline-block;
    margin-right: 0px; }
    .btn-step .btn-step-child.btn-next .btn-arrow-text {
      padding-right: 5px; }
  .btn-step .btn-step-child .btn-arrow {
    background: url(../images/arrow.svg) no-repeat 0 0;
    display: inline-block;
    height: 16px; }
    .btn-step .btn-step-child .btn-arrow.left {
      background-position: 0 0;
      padding-left: 25px; }
    .btn-step .btn-step-child .btn-arrow.right {
      background-position: 0 -16px;
      width: 17px;
      position: relative;
      top: -1px; }
  .btn-step .btn-step-child:focus {
    outline: none; }

.btn-step-child[disabled] {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none; }

@media screen and (min-width: 768px) {
  .btn-block-sm {
    display: block;
    width: 100%; } }

.button-reset {
  border: none;
  background: none;
  outline: 0;
  box-shadow: none; }

.underline {
  text-decoration: underline; }

.not_allowed {
  cursor: not-allowed !important; }

@media screen and (min-width: 768px) {
  .w-auto-md {
    width: auto !important; } }

.w-auto {
  width: auto; }

.w-60 {
  width: 60px; }

.w-120 {
  width: 120px; }

.w-240 {
  width: 240px; }

.w-270 {
  width: 270px; }

.pl-0 {
  padding-left: 0 !important; }

.pl-10 {
  padding-left: 10px; }

.pl-40 {
  padding-left: 40px; }

@media screen and (min-width: 768px) {
  .pl-sm-40 {
    padding-left: 40px !important; } }

@media screen and (min-width: 768px) {
  .pl-sm-30 {
    padding-left: 30px !important; } }

@media screen and (min-width: 768px) {
  .pl-sm-10 {
    padding-left: 10px !important; } }

@media screen and (min-width: 992px) {
  .pl-lg-10 {
    padding-left: 10px !important; } }

.pr-10 {
  padding-right: 10px; }

@media screen and (min-width: 768px) {
  .pl-sm-0 {
    padding-left: 0 !important; } }

@media screen and (min-width: 1280px) {
  .pl-xl-10 {
    padding-left: 10px !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0 {
  margin-top: 0 !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-5 {
  margin-right: 5px; }

.mt-15px {
  margin-top: 15px; }

.mt-10px {
  margin-top: 10px; }

.mt-5px {
  margin-top: 5px; }

@media screen and (min-width: 768px) {
  .mt-sm-0 {
    margin-top: 0 !important; } }

@media screen and (min-width: 768px) {
  .mt-sm-10px {
    margin-top: 10px !important; } }

@media screen and (min-width: 768px) {
  .mt-sm-15px {
    margin-top: 15px !important; } }

@media screen and (min-width: 1280px) {
  .mt-xl-0 {
    margin-top: 0 !important; } }

.mt-12 {
  margin-top: 12px; }

.mt-20 {
  margin-top: 20px; }

.mt-30 {
  margin-top: 30px; }

.mt-40 {
  margin-top: 40px; }

.ml-8 {
  margin-left: 8px; }

.ml-10 {
  margin-left: 10px; }

.ml-0 {
  margin-left: 0 !important; }

@media screen and (min-width: 768px) {
  .ml-md-10 {
    margin-left: 10px !important; } }

@media screen and (min-width: 768px) {
  .ml-md-15 {
    margin-left: 15px !important; } }

.mb-10 {
  margin-bottom: 10px; }

.mb-15 {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .mb-md-15 {
    margin-bottom: 15px !important; } }

.mb-35 {
  margin-bottom: 35px; }

@media screen and (min-width: 768px) {
  .mb-sm-0 {
    margin-bottom: 0 !important; } }

.font-s15 {
  font-size: 15px; }
  .font-s15 a, .font-s15 p, .font-s15 span {
    font-size: 15px; }

button span {
  font-size: 14px; }

@media screen and (min-width: 768px) {
  .age {
    width: 7rem !important; } }

@media screen and (min-width: 1280px) {
  .d-xl-none {
    display: none; } }

@media screen and (min-width: 992px) {
  .d-lg-none {
    display: none !important; } }

@media screen and (min-width: 768px) {
  .d-md-none {
    display: none; } }

@media screen and (min-width: 768px) {
  .d-sm-block {
    display: block; } }

.cursor-default {
  cursor: default !important; }

@media screen and (min-width: 801px) {
  .main {
    min-height: calc(100% - 202px);
    min-height: -moz-calc(100% - 182px); } }

@media screen and (max-width: 800px) {
  .main {
    min-height: calc(100% - 202px);
    min-height: -moz-calc(100% - 182px); } }

.page--home .alert-danger {
  background-color: transparent;
  border-color: transparent;
  padding-bottom: 5px;
  margin-bottom: 0; }

.page--request .frm-reset {
  border: 1px solid #ccc;
  margin-top: 40px;
  padding-bottom: 25px;
  margin-bottom: 40px; }
  .page--request .frm-reset .alert-warning,
  .page--request .frm-reset .alert-danger,
  .page--request .frm-reset .alert-success {
    border-color: transparent;
    background-color: #f2f2f2;
    position: relative;
    top: -16px;
    padding: 5px 20px;
    max-width: 85%;
    display: inline-block;
    margin-bottom: 0; }
  .page--request .frm-reset input[type=email] {
    width: 80%;
    margin: 0 auto 20px; }

.page--password .frm-password {
  border: 1px solid #ccc;
  margin-top: 20px;
  padding: 30px;
  margin-bottom: 40px; }
  .page--password .frm-password .alert-warning,
  .page--password .frm-password .alert-danger,
  .page--password .frm-password .alert-success {
    border-color: transparent;
    background-color: transparent;
    padding: 0;
    margin-bottom: 25px; }

.page--thank-you .thank, .page--home .thank, .page--survey-workflow .thank {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  color: #fff;
  text-align: center;
  font-size: 20px; }
  .page--thank-you .thank .glyphicon, .page--home .thank .glyphicon, .page--survey-workflow .thank .glyphicon {
    top: 12px; }

.page--thank-you {
  color: #363E52; }
  .page--thank-you .thank {
    background-color: #002D5D; }

.page--home .thank {
  background-color: #002D5D; }
  .page--home .thank.warning {
    font-size: 30px;
    line-height: 1.7; }

.page--survey-result-details .table > thead > tr > th {
  width: 17%; }

.page--survey-workflow .thank {
  background-color: #002D5D;
  margin-top: 4px; }

.page--survey-workflow .battery {
  font-size: 15px; }
  .page--survey-workflow .battery p:first-child b:first-child {
    color: #002D5D;
    font-size: 18px; }
  .page--survey-workflow .battery p {
    color: #363E52; }

.page--survey-workflow .question-name, .page--survey-workflow .section-name, .page--survey-workflow .question-text {
  color: #363E52; }

.page--survey-workflow .table-responsive .table > thead > tr > th {
  color: #363E52; }

.page--survey-workflow .section-name {
  font-size: 14px; }

.page--survey-workflow .select-one .radio label {
  display: block;
  line-height: 20px;
  padding-bottom: 5px;
  color: #363E52; }

.page--survey-workflow .select-one .radio textarea {
  margin-top: 10px;
  margin-left: 20px; }
  .page--survey-workflow .select-one .radio textarea.w-30 {
    width: 30%; }

.page--survey-workflow .select-one .radio input[type="radio"] {
  top: 4px; }

.page--survey-workflow .pagination .disabled span,
.page--survey-workflow .pagination .disabled span:hover,
.page--survey-workflow .pagination .disabled span:focus,
.page--survey-workflow .pagination .disabled a,
.page--survey-workflow .pagination .disabled a:hover,
.page--survey-workflow .pagination .disabled a:focus {
  color: #363E52; }

.page--survey-workflow .form-group .form-control, .page--survey-workflow .jump-to {
  color: #363E52; }

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .w-30 {
    width: 50% !important; } }

@media screen and (max-width: 767px) {
  .w-30 {
    width: 90% !important; } }

@media screen and (max-width: 1024px) {
  .page--survey-workflow .select-one input[type="radio"] {
    position: relative;
    margin-right: 5px; }
  .page--survey-workflow .select-one .radio + .radio {
    margin-top: 0; } }

@media screen and (max-width: 1024px) {
  .page--register input[type="radio"] {
    position: relative;
    top: 0; } }

@media screen and (max-width: 767px) {
  .page--register .radio-inline {
    margin-top: 5px; } }

.yourBack {
  width: 100px; }

.es-lang .yourBack {
  width: 112px; }

.fix-height .category {
  margin-bottom: 1rem;
  margin-top: 2rem; }

@media screen and (min-width: 992px) {
  .fix-height:nth-child(1) .category, .fix-height:nth-child(2) .category, .fix-height:nth-child(3) .category, .fix-height:nth-child(4) .category {
    margin-top: 0; } }

@media screen and (min-width: 768px) {
  .fix-height:nth-child(1) .category, .fix-height:nth-child(2) .category, .fix-height:nth-child(3) .category {
    margin-top: 0; } }

@media screen and (max-width: 767px) {
  .fix-height:nth-child(1) .category {
    margin-top: 0; } }

@media (min-width: 768px) {
  .es-lang .d-md-flex-es-lang-w-100 {
    display: flex;
    width: 100%; } }

.yourLeg,
.yourArm {
  width: 90px; }

.es-lang .yourLeg {
  width: 100px; }

.es-lang .yourArm {
  width: 102px; }

@media screen and (max-width: 991px) {
  .es-lang .d-es-lang-lg-none {
    display: block !important; } }

@media screen and (min-width: 768px) {
  .d-es-lang-lg-none {
    display: none !important; } }

@media screen and (max-width: 375px) {
  .es-lang .currentIndications-q8 .icon-required,
  .es-lang .currentIndications-q16 .icon-required {
    position: absolute;
    right: -3px;
    top: 9px; }
  .relatedInjuries-q5 .icon-required {
    position: absolute;
    right: -10px;
    top: 4px; } }

@media screen and (max-width: 360px) {
  .es-lang .currentIndications-q5 .icon-required {
    position: absolute;
    right: -1px;
    top: 30px; }
  .es-lang .currentIndications-q8 .icon-required,
  .es-lang .currentIndications-q16 .icon-required {
    position: relative;
    top: -4px;
    right: 0; }
  .es-lang .currentIndications-q15 .icon-required {
    position: absolute;
    right: 3px;
    top: 9px; }
  .es-lang .relatedInjuries-q6 .icon-required {
    position: absolute;
    right: -10px;
    top: 4px; }
  .relatedInjuries-q5 .icon-required {
    position: relative;
    top: -4px;
    right: 0; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome.d-md-block, .safari_5.d-md-block {
    display: none !important; }
  ::i-block-chrome.d-md-none, .safari_5.d-md-none {
    display: block !important; } }

ul.bullet.bullet-check {
  list-style: none;
  padding-left: 10px; }
  @media screen and (min-width: 768px) {
    ul.bullet.bullet-check {
      width: 85%;
      margin: auto; } }
  ul.bullet.bullet-check li {
    padding-left: 25px;
    padding-bottom: 4px;
    background: transparent url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAAQCAMAAAD3Y3VMAAADAFBMVEX///////Pjz8rKydbn+f/zzaGIgICQtuP/99KliICAkrnn///esIyAgpC55f//672UgoCMsN7///fOooSAiKnW/f///e/WtqW53f//5bWQgICcxfOUgICMst7SqISAjLHe///GnoDe672UgIShzff/3bGMgISQkozGnICAgICcx/eUjrEAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAAAAAAObShoSHcAdw4AAAAAAAAAAQAhAAB3rykZ7yAAAABwAAAAAAAAAAAIAAB7AAB3rykAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAOaOwAAHcAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAADSAAB3sm2sGzAAAAACAJ0AAAAAAAAAAAAAAAAAAAAAAAAAAABkAAAAAAAAAAALsAASAKEAAAAAAAAAFACQABIArBKsGzALoAAAAKEAnQAAAADvnAC2ABl3rdywAnkAAHcAAAAAAAAAAAAAAAAAAAAAAABmp58lXdZQdwEAGfABJXsAt3cAAAAAAAAAAAAlpMBgdwEAAAAAAAAAAAAFAAAAAAAQAIAMhMCAAAAAAAAAAAAAAwAAAAAAAABqAGgbMAAAAKwAAAAAAAAbMAAAAKwAAACdAADwJAAAABkAAAAAABgAAADkAAAAGe8AAEAAAABAAAAAGfAAAAAAAAAAAAAAAAAAAAAACgBYAAAAqjlmuTcAANYMAJ0AAAAAAAIBAQACAES9ToQZ8IAiLgAAdwEAAAAAAALwaAAAABkAAAAAABgAgAAAAAAAAAAAAAAAAAAAAAAK8kjvAAAAAWJLR0QAiAUdSAAAAAlwSFlzAAAOxAAADsQBlSsOGwAAAFNJREFUeNpjYCAPMDIxs8DYrGzsMCYHJxc3jM3Dywdj8gsIwphCwiKiIFpMXIJBUkoaIi0jyyAnrwCRV5RXUlaBKlZVU4cKA23U0NSCW8+vDRUGAORsBGI8snYqAAAAAElFTkSuQmCC") no-repeat; }

ul.bullet.bullet-decimal {
  list-style: decimal;
  padding-left: 15px; }

ul.bullet.bullet-decimal-privacy {
  list-style: decimal;
  padding-left: 23px; }
  ul.bullet.bullet-decimal-privacy li {
    padding-bottom: 10px; }

ul.bullet li {
  padding-left: 15px;
  padding-bottom: 4px; }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome, .bullet-decimal {
    padding-left: 20px !important; }
  ::i-block-chrome, .bullet-decimal-privacy {
    padding-left: 25px !important; } }

.signature-placeholder {
  background-color: #eee;
  border: 1px solid #dcdcdc;
  border-radius: 4px;
  padding: 30px 10px;
  text-align: center; }

/*LandingPage*/
.landing-page {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  color: black;
  font-size: 1.7rem; }
  .landing-page .landing-page-background {
    min-height: 360px;
    image-rendering: -webkit-optimize-contrast; }
    .landing-page .landing-page-background.landing-page-start {
      background: url("../images/assets_bg.jpg") no-repeat left top;
      background-size: cover; }
      .landing-page .landing-page-background.landing-page-start .title-landing {
        max-width: 270px; }
      .landing-page .landing-page-background.landing-page-start .title-description {
        max-width: 415px; }
    .landing-page .landing-page-background.landing-page-completed {
      background: url("../images/assets_bg_2.jpg") no-repeat left top;
      background-size: cover; }
      .landing-page .landing-page-background.landing-page-completed .title-landing {
        max-width: 390px; }
      .landing-page .landing-page-background.landing-page-completed .title-description {
        max-width: 305px; }
    .landing-page .landing-page-background .carousel-caption {
      color: #fff;
      text-align: left;
      right: 0;
      top: 0;
      bottom: 0;
      left: 0;
      position: relative;
      padding-left: 10px;
      min-height: 360px; }
      @media screen and (min-width: 667px) {
        .landing-page .landing-page-background .carousel-caption {
          color: #002D5D;
          text-shadow: none;
          left: 50%;
          padding-bottom: 0;
          padding-top: 0;
          width: 50%;
          padding-left: 0; } }
      .landing-page .landing-page-background .carousel-caption .title-landing {
        font-size: 3.5rem;
        font-family: 'AcuminPro-Cond-Bold';
        line-height: 1;
        padding-bottom: 10px; }
      .landing-page .landing-page-background .carousel-caption .title-description {
        font-family: 'AcuminPro-Cond-Bold';
        font-size: 2rem;
        line-height: 1.2;
        padding-bottom: 3rem; }
        @media screen and (max-width: 667px) {
          .landing-page .landing-page-background .carousel-caption .title-description {
            max-width: 280px; } }
    .landing-page .landing-page-background .carousel {
      cursor: default; }
  .landing-page .landing-page-wrap {
    padding: 0 30px; }
    @media screen and (max-width: 768px) {
      .landing-page .landing-page-wrap {
        padding: 0; } }
    @media screen and (max-width: 767px) {
      .landing-page .landing-page-wrap .col-v4-sm-7:first-child .wrap-box {
        margin: 0; } }
    .landing-page .landing-page-wrap .wrap-box {
      background-color: #F2F2F2;
      border-radius: 5px;
      overflow: hidden;
      margin: 10px 10px 0 10px;
      transition: background-color .15s;
      height: 235px; }
      .landing-page .landing-page-wrap .wrap-box:hover {
        background-color: #e5eae7; }
      @media screen and (max-width: 1024px) {
        .landing-page .landing-page-wrap .wrap-box {
          margin: 10px 0 0 0; } }
      @media screen and (max-width: 768px) {
        .landing-page .landing-page-wrap .wrap-box {
          margin: 10px 10px 0 10px; } }
      @media screen and (max-width: 767px) {
        .landing-page .landing-page-wrap .wrap-box {
          margin: 30px 0 0 0; } }
      .landing-page .landing-page-wrap .wrap-box .content-box {
        border-top: 8px solid #002D5D;
        text-align: center;
        padding: 22px 0 10px 0;
        color: #002D5D;
        font-family: 'AcuminPro-Semibold'; }
        .landing-page .landing-page-wrap .wrap-box .content-box img {
          max-height: 70px; }
        .landing-page .landing-page-wrap .wrap-box .content-box .title-box {
          padding: 15px 0;
          max-width: 205px;
          margin: auto;
          line-height: 1.2;
          font-size: 2rem; }
        .landing-page .landing-page-wrap .wrap-box .content-box .small {
          font-size: 92%; }
    .landing-page .landing-page-wrap .multipleNpqText {
      background-color: #002D5D;
      color: #fff;
      padding: 0.25rem 0.5rem 1rem 0.5rem;
      border-radius: 0 0 5px 5px;
      line-height: 1.2;
      margin: 0 3rem; }

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical; }

.expand-icon {
  width: 26px;
  height: 26px;
  background: transparent url("../../content/images/expand-icon.svg") no-repeat;
  transition: all .15s ease-in-out; }
  .expand-icon.active, .expand-icon:hover, .expand-icon:focus {
    background: transparent url("../../content/images/expand-icon-hover.svg") no-repeat; }

.text-reset, .text-reset:hover {
  text-decoration: underline;
  color: #808080;
  font-weight: normal; }

.col-v4-95 {
  flex: 0 0 95px;
  max-width: 95px;
  width: 100%;
  padding-right: 10px;
  padding-left: 10px; }

.checkbox-row {
  background-color: #fff;
  padding: 10px 0 10px 10px; }
  .checkbox-row:nth-of-type(odd) {
    background-color: #f9f9f9; }
  .checkbox-row .checkbox-column {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    padding-right: 10px;
    padding-left: 10px; }
    @media (min-width: 768px) {
      .checkbox-row .checkbox-column:nth-child(2) {
        flex: 0 0 145px;
        max-width: 145px; } }
    @media (max-width: 767px) {
      .checkbox-row .checkbox-column:nth-child(2) {
        flex: 0 0 80px;
        max-width: 80px; } }
    .checkbox-row .checkbox-column:nth-child(2) .form-row-v4 .col-v4 {
      display: none; }

.col-v4-checkbox {
  padding-right: 10px;
  padding-left: 10px;
  text-align: center;
  font-weight: bold; }
  @media (min-width: 768px) {
    .col-v4-checkbox {
      flex: 0 0 145px;
      max-width: 145px;
      white-space: nowrap; } }
  @media (max-width: 767px) {
    .col-v4-checkbox {
      flex: 0 0 80px;
      max-width: 80px; } }

.header-list {
  border-bottom: 2px solid #ddd; }

.limit-width {
  flex-wrap: nowrap !important; }
  @media (min-width: 1200px) {
    .limit-width {
      width: 85%; } }

.wrapword {
  white-space: -moz-pre-wrap !important;
  white-space: -webkit-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  white-space: pre-wrap;
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal; }

.step {
  display: flex;
  counter-reset: custom-counter 5;
  list-style-type: none;
  line-height: 1.2;
  margin: 0;
  padding: 0 30px 0 0; }
  .step .indicator .pointer {
    display: flex;
    align-items: center;
    color: #fff;
    padding-left: 1em;
    height: 60px;
    position: relative; }
    .step .indicator .pointer span {
      z-index: 3; }
    .step .indicator .pointer:after {
      content: "";
      display: block;
      position: absolute;
      z-index: 2;
      top: 50%;
      left: 100%;
      border: solid #fff;
      border-width: 2px 0 0 2px;
      width: 42.42641px;
      height: 42.42641px;
      transform: rotate(135deg) translateY(calc(50% + 8.7868px));
      background-color: inherit; }
    .step .indicator .pointer.status-indicator {
      background: #adb5bd; }
    .step .indicator .pointer.status-indicator-active {
      background: #4573C4; }
  .step .indicator:last-child .pointer:after {
    border: 0; }
  .step .indicator + .indicator .pointer {
    padding-left: 3em; }

.d-inline-block {
  display: inline-block; }

.border-danger {
  font-weight: bold;
  display: inline-block;
  text-align: center;
  padding: 0;
  margin-left: 5px;
  width: 16px;
  cursor: pointer;
  font-family: "AcuminPro-Semibold", sans-serif !important;
  position: relative; }
  .border-danger:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #a94442; }
    @supports (-moz-appearance: none) {
      .border-danger:before {
        top: 3.5px; } }

.border-success {
  font-weight: bold;
  display: inline-block;
  text-align: center;
  padding: 0;
  margin-left: 5px;
  width: 16px;
  cursor: pointer;
  font-family: "AcuminPro-Semibold", sans-serif !important;
  position: relative; }
  .border-success:before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 2px;
    left: 0;
    width: 16px;
    height: 16px;
    border: 2px solid #3c763d; }
    @supports (-moz-appearance: none) {
      .border-success:before {
        top: 3.5px; } }

/* physical-demand-levels */
@media screen and (min-width: 768px) {
  .physical-demand-levels .col-label {
    flex: 0 0 110px;
    max-width: 110px; } }

/* objective */
.objective {
  border: 1px solid #ddd;
  border-top: 0; }
  .objective .objective-header {
    background-color: #f9f9f9;
    padding: 12px 40px;
    border-top: 1px solid #ddd;
    position: relative; }
    .objective .objective-header:hover .expand-icon {
      background: transparent url("../../content/images/expand-icon-hover.svg") no-repeat; }
    .objective .objective-header .expand-icon {
      position: absolute;
      right: 12px;
      top: 10px; }
    .objective .objective-header.has-checked {
      background: #f9f9f9 url("../../content/images/checked.svg") no-repeat 12px center; }
  .objective .objective-content {
    border-top: 1px solid #ddd;
    padding: 12px; }

@media screen and (min-width: 768px) {
  .col-v4-md-objective {
    flex: 0 0 310px;
    max-width: 310px; } }

.normal-input {
  width: calc(100% - 12.95px);
  margin-left: 12.95px; }

.add-other-objective {
  font-weight: 700;
  color: #0D9C93;
  font-family: AcuminPro-Light,sans-serif; }

.add-other-objective:hover {
  text-decoration: underline; }

.other-remove {
  font-size: 15px;
  padding-left: 3px; }

.other-object-input-name {
  position: relative;
  left: -10px; }

/* goals */
.position-relative .form-control {
  text-align: right; }

/* status Progress Tracker */
.progressTracker > [class*=col-] {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.progressTracker .statusPTracker {
  height: 1.6em;
  width: 1.6em;
  margin-top: 0.25em;
  border: 0.06em solid #666;
  border-radius: 0.25em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; }
  .progressTracker .statusPTracker .glyphicon {
    top: 0; }
  .progressTracker .statusPTracker.completed {
    color: #0C0; }
  .progressTracker .statusPTracker.inProgress {
    color: #bee7ff; }

.text-break {
  white-space: nowrap; }

.modal-header {
  background-color: #0D9C93;
  color: #fff;
  border-bottom: transparent;
  border-radius: 4px 4px 0 0;
  padding: 10px 20px; }
  .modal-header .modal-title {
    font-family: "AcuminPro-Medium", sans-serif !important;
    font-size: 2.2rem; }
  .modal-header .modal-title.title-small {
    font-size: 2rem; }
  .modal-header .close {
    margin-top: 3px;
    color: #fff;
    text-shadow: none;
    font-weight: normal; }

.modal-body {
  padding: 15px 20px; }

.modal-footer {
  border-top: 1px solid #fff;
  border-radius: 0 0 4px 4px;
  text-align: center;
  padding: 0 15px 15px 15px; }

.absolute-center .modal-dialog .modal-body {
  text-align: center;
  padding: 20px 30px; }

@media (min-width: 768px) {
  .modal-sm {
    width: 400px; }
  .absolute-center .modal-dialog {
    position: absolute !important;
    left: 50% !important;
    margin-top: 160px;
    -webkit-transform: translate(-50%) !important;
    transform: translate(-50%) !important; } }

@media (max-width: 767px) {
  .vertical-center .modal-dialog {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 97vh; } }

.table-responsive a {
  font-weight: normal;
  color: #808080; }
  .table-responsive a:hover {
    color: #808080;
    text-decoration: none; }

.table-responsive .w8 {
  width: 8%; }
  .table-responsive .w8.q11 {
    width: 5%;
    padding: 0 0 8px 0; }
  .table-responsive .w8.sec-cesd {
    width: 15%;
    white-space: normal !important; }

.table-responsive .pt-8 {
  padding: 8px 0 0 0; }

.table-responsive .table > thead > tr > th {
  white-space: nowrap; }
  .table-responsive .table > thead > tr > th.w1 {
    width: 1px; }
  .table-responsive .table > thead > tr > th.expand {
    width: 70px; }
  .table-responsive .table > thead > tr > th.date {
    width: 100px; }
  .table-responsive .table > thead > tr > th.w-101 {
    width: 101px; }
  .table-responsive .table > thead > tr > th.w-107 {
    width: 107px; }
  .table-responsive .table > thead > tr > th.address {
    min-width: 115px; }
  .table-responsive .table > thead > tr > th.w-121 {
    width: 121px; }
  .table-responsive .table > thead > tr > th.authDate {
    width: 150px; }
  .table-responsive .table > thead > tr > th.w-40-p {
    width: 40% !important; }
  .table-responsive .table > thead > tr > th.w-35-p {
    width: 35% !important; }
  .table-responsive .table > thead > tr > th.w-20-p {
    width: 20% !important; }
  .table-responsive .table > thead > tr > th.w-5-p {
    width: 5% !important; }

.table-responsive .table > tbody > tr.mask {
  background-color: rgba(0, 0, 0, 0.08); }
  .table-responsive .table > tbody > tr.mask:nth-of-type(odd) td {
    background-color: #e5e5e5; }

.table-responsive .table > tbody > tr.greyed-out-row {
  background-color: #AAAFB4;
  color: white; }

.table-responsive .table-notification > tbody > tr.odd {
  background-color: #f9f9f9; }
  .table-responsive .table-notification > tbody > tr.odd + .odd-child {
    background-color: #f9f9f9; }
    .table-responsive .table-notification > tbody > tr.odd + .odd-child .table > thead,
    .table-responsive .table-notification > tbody > tr.odd + .odd-child .table > tbody {
      background-color: #f9f9f9; }

.table-responsive .table-notification > tbody > tr.odd + .odd-child .table,
.table-responsive .table-notification > tbody > tr.odd + .even-child .table, .table-responsive .table-notification > tbody > tr.even + .odd-child .table,
.table-responsive .table-notification > tbody > tr.even + .even-child .table {
  width: 95%;
  margin: 20px auto; }

@media screen and (max-width: 767px) {
  .table thead tr {
    border-bottom: 2px solid #ddd; }
    .table thead tr th {
      white-space: normal !important; }
      .table thead tr th.w8 {
        width: auto !important; }
        .table thead tr th.w8.q11 {
          width: 0 !important;
          padding: 8px !important; }
  .table tbody tr td.pt-8 {
    padding: 7px !important; }
  .table tbody tr td.question-text {
    white-space: normal;
    min-width: 170px; } }

@media only screen and (-webkit-min-device-pixel-ratio: 1) {
  ::i-block-chrome, .table > thead > tr > th {
    -webkit-text-stroke-width: .6px;
    -webkit-text-stroke-color: gray; }
  ::i-block-chrome, .table > thead > tr > th .glyphicon {
    -webkit-text-stroke-width: 0; } }

.body-wrap {
  min-height: 100%;
  position: relative; }
  .body-wrap .main {
    padding-bottom: 47px; }

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: #1c1e1f;
  min-height: 47px;
  padding: 13px 0; }

.copyright,
.description {
  color: gray; }

@media screen and (min-width: 568px) {
  .copyright {
    text-align: left;
    padding-left: 10px;
    float: left; }
  .description {
    text-align: right;
    float: right;
    padding-right: 10px; } }

@media screen and (max-width: 567px) {
  .copyright, .description {
    text-align: center; } }

.nav-tabs .uib-tab a {
  border-bottom-color: #ccc;
  font-weight: normal; }

.nav-tabs .uib-tab.active a {
  border-bottom-color: transparent;
  font-weight: bold; }

.nav-tabs .uib-tab.primary a {
  color: #002D5D; }
  .nav-tabs .uib-tab.primary a:hover, .nav-tabs .uib-tab.primary a:focus {
    color: #002D5D; }

.tab-content {
  padding: 30px 20px 20px;
  border: 1px solid #ddd;
  border-top-width: 0; }

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak {
  display: none !important; }

.development {
  position: absolute;
  width: 149px;
  height: 149px;
  top: 0;
  left: 0;
  border: 0;
  z-index: 1000;
  background-image: url("../images/development_ribbon.png");
  background-position: center center;
  background-repeat: no-repeat;
  pointer-events: none; }

.navbar-version {
  font-size: 10px;
  color: #ccc; }

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.error {
  color: white;
  background-color: red; }

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/hipster.png") no-repeat center top;
  background-size: contain; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.menu {
  position: relative; }
  .menu .sub-menu {
    display: none;
    position: absolute;
    z-index: 1000;
    background: #FFF;
    left: -175px;
    top: -6px;
    border: 1px solid #ccc;
    background-clip: padding-box; }
    .menu .sub-menu .sub-menu-item {
      padding: 10px 15px !important; }

.menu:hover .sub-menu {
  display: block; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2 / 1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../images/hipster2x.png") no-repeat center top;
    background-size: contain; } }

/* make sure browsers use the pointer cursor for anchors, even with no href */
a:hover {
  cursor: pointer; }

.hand {
  cursor: pointer; }

#threadDump .popover,
#healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

/* start Password strength bar style */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* end Password strength bar style */
/* Custom alerts for notification */
.alerts .alert {
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  width: 100%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%; } }

/* end of Custom alerts for notification */
/* entity tables helpers */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-end;
  justify-content: flex-end; }

.flex-btn-group-container-left {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-justify-content: flex-start;
  justify-content: flex-start; }

.jh-table > tbody > tr > td {
  /* Align text in td verifically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort,
.jh-table > thead > tr > th > .glyphicon-sort-by-attributes,
.jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover,
  .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover,
  .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

.table-scroll-h {
  height: 220px;
  overflow-y: auto; }

.table-psych {
  overflow-x: visible; }

.column-nowrap {
  white-space: nowrap; }

/* end of entity tables helpers */
/* ui bootstrap tweaks */
.nav,
.pagination,
.carousel,
.panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-info,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-info,
.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default.active,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default.active {
  border: 1px solid #4a71a3;
  -webkit-box-shadow: none;
  box-shadow: none; }
  .datetime-picker-dropdown > li.date-picker-menu div > table .btn-info span,
  .uib-datepicker-popup > li > div.uib-datepicker > table .btn-info span,
  .datetime-picker-dropdown > li.date-picker-menu div > table .btn-default.active span,
  .uib-datepicker-popup > li > div.uib-datepicker > table .btn-default.active span {
    color: #4a71a3;
    font-weight: bold; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

.time-picker .uib-separator {
  padding: 3px; }

.time-picker .uib-increment > a.btn.btn-link,
.time-picker .uib-decrement > a.btn.btn-link {
  color: #4a71a3 !important; }

.time-picker .uib-time.am-pm {
  padding-left: 6px; }

.time-picker .uib-separator {
  padding: 3px; }

/* end of ui bootstrap tweaks */
/* jhipster-needle-scss-add-main JHipster will add new css style */
.form-inline {
  margin: 10px 0px 10px 0px;
  /*table {
        width: 100%;
        border: 1px solid #000;
        th, td {
            padding: 5px;
            border: 1px solid #000;
        }
    }*/ }

.signature {
  width: 302px;
  height: 202px;
  vertical-align: middle; }

.signature img {
  height: auto;
  max-width: 100%;
  max-height: 100%; }

.btn-secondary-gray {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d; }

.btn.btn-smaller {
  padding: 0px 2px 0px 1px; }
  .btn.btn-smaller span.glyphicon.glyphicon-trash {
    font-size: 10px; }

.text-summary {
  resize: none; }

.col-party {
  max-width: 200px; }

.col-party-other {
  margin-top: -15px; }

.form-group .form-control.ng-invalid-error-max-date, .form-group .form-control.ng-invalid-has-error {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.08); }

/**presentation-controls**/
pdf-viewer > canvas {
  z-index: 99;
  position: relative;
  display: inline-block; }

.presentation-controls {
  padding: 0 10px 15px;
  margin-bottom: 15px; }
  .presentation-controls .presentation-title {
    width: 40px;
    margin-top: -12px;
    margin-left: 10px;
    background: white;
    padding-left: 1px; }

.class-notes {
  overflow: auto; }

.speaker-note,
.pdf-viewer {
  height: calc(100vh - 250px); }

.speaker-note {
  margin-top: 5px;
  padding: 5px;
  overflow: auto; }

.pdf-viewer {
  text-align: center;
  z-index: 99;
  margin-top: 5px;
  margin-bottom: 10px;
  overflow: hidden;
  display: flex; }

.pdf-viewer-scroll-content {
  flex: 1; }
  .pdf-viewer-scroll-content.instructor {
    margin-left: -15px; }

.pdf-viewer-container {
  padding-top: 40px; }

.pdf-viewer-toolbar {
  position: absolute;
  background-color: #fff;
  top: 6px;
  left: 11px;
  right: 11px;
  height: 40px;
  z-index: 100; }

.presentation-controls,
.pdf-viewer,
.speaker-note {
  border: 1px solid #bbbbbc; }

.presentation-instructor-content {
  max-width: 1380px;
  margin: 0 auto; }

.toolbarButton {
  padding: 2px 6px 0;
  border: none;
  border-radius: 2px;
  user-select: none;
  cursor: default;
  box-sizing: border-box;
  background: none;
  min-width: 16px;
  margin: 2px 1px;
  padding: 2px 6px 0;
  border: none;
  border-radius: 2px;
  color: #0c0c0d;
  font-size: 12px;
  line-height: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  cursor: default;
  box-sizing: border-box; }

.toolbarField {
  padding: 4px 7px;
  margin: 3px 0;
  border-radius: 2px;
  background-color: white;
  background-clip: padding-box;
  border-width: 1px;
  border-style: solid;
  border-color: #bbbbbc;
  box-shadow: none;
  color: #060606;
  font-size: 12px;
  line-height: 16px;
  outline-style: none; }
  .toolbarField .pageNumber {
    -moz-appearance: textfield;
    min-width: 16px;
    text-align: right;
    width: 40px; }

.presentation-view {
  display: flex;
  flex-direction: column;
  margin: -20px 0 -40px;
  height: calc(100vh - 55px); }
  .presentation-view .container {
    flex: 1;
    max-height: calc(100% - 130px); }
  .presentation-view .header {
    padding: 10px 0 20px;
    background-color: #fff; }
    .presentation-view .header .img-logo img {
      height: 7vh;
      min-height: 53px; }
  .presentation-view .content {
    margin: 20px 0 10px;
    display: flex;
    flex-direction: column;
    height: 100%; }
  .presentation-view .presentation-view-name {
    padding: 0 5px; }
  .presentation-view .presentation-view-content {
    overflow: hidden;
    padding: 10px 10px 0;
    text-align: center;
    flex: 1;
    margin: 10px 15px 0;
    border: 1px solid #bbbbbc; }

.scrollContainer {
  cursor: grab;
  overflow: hidden; }

.btn-bn-item {
  padding-left: 5px; }
  .btn-bn-item button {
    border-radius: 6px; }

.class-session-detail-modal .modal-dialog {
  position: absolute;
  top: 25%;
  left: 0;
  right: 0; }

.table-scroll-class {
  max-height: 350px;
  overflow-y: auto; }

/***attendanceTracker*/
.checkBox-at input[type="checkbox"],
.checkBox-at input[type="radio"] {
  position: absolute;
  left: -999em; }

.checkBox-at input[type="checkbox"] + * {
  display: inline-block;
  line-height: 1.6em;
  height: 1.6em;
  width: 1.6em;
  margin-top: 0.25em;
  vertical-align: top;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  text-decoration: none;
  border: 0.06em solid #666;
  transition: all 0.2s;
  cursor: pointer; }

.checkBox-at input[type="checkbox"] + *:before {
  content: "";
  position: relative;
  display: block;
  transition: all 0.5s; }

.checkBox-at input[type="checkbox"] + i,
.checkBox-at input[type="checkbox"] + ins {
  border-radius: 0.25em; }

.checkBox-at input[type="checkbox"] + i:before {
  content: "\2714";
  top: -0.1em;
  left: 0.1em;
  color: #0C0;
  transform: scale(0);
  transform-origin: 50% 65%; }

.checkBox-at input[type="checkbox"]:checked + i:before,
.checkBox-at input[type="checkbox"]:checked + ins:before {
  transform: scale(1); }

.checkBox-at input[type="checkbox"] + ins:before {
  content: "\1F5D9";
  top: -0.1125em;
  left: -0.0625em;
  color: #F00;
  transform: scale(0); }

.checkBox-at input[type="checkbox"] + b {
  width: auto;
  height: auto;
  background: #FCC;
  padding-right: 0.5em;
  border: 0.125em solid #C00;
  border-radius: 1em; }

.checkBox-at input[type="checkbox"] + b:before {
  left: 0;
  width: 0.75em;
  height: 0.75em;
  background: #C00;
  border: 0.125em solid #FCC;
  border-radius: 50%; }

.checkBox-at input[type="checkbox"]:checked + b {
  background: #8F8;
  border-color: #0A0; }

.checkBox-at input[type="checkbox"]:checked + b:before {
  left: 0.5em;
  background: #0A0;
  border-color: #8F8; }

.vital-signs-height {
  max-width: 50px; }

.patient-card-collapse-btn {
  color: #002D5D;
  margin: 20px 0 15px;
  background-color: transparent;
  border-width: 0;
  font-weight: bold;
  font-size: 16px;
  padding: 0; }

.patient-card-accordion {
  margin-bottom: 15px; }
  .patient-card-accordion .glyphicon-minus:before {
    width: 17px; }
  .patient-card-accordion .radio-inline, .patient-card-accordion .checkbox-inline {
    margin-bottom: 4px;
    margin-left: 5px; }
    .patient-card-accordion .radio-inline input[type="radio"], .patient-card-accordion .checkbox-inline input[type="radio"] {
      top: 3px;
      margin-left: -18px; }
  .patient-card-accordion textarea {
    resize: none;
    width: 100%; }
  .patient-card-accordion > .panel-heading > .panel-title {
    word-break: break-word;
    padding: 0 20px 0 15px;
    position: relative; }
  .patient-card-accordion > .panel-collapse > .panel-body {
    padding: 0; }
    .patient-card-accordion > .panel-collapse > .panel-body .panel-group {
      margin-bottom: 0; }
    .patient-card-accordion > .panel-collapse > .panel-body .panel-heading {
      padding: 10px 15px;
      border-radius: 0; }
    .patient-card-accordion > .panel-collapse > .panel-body .panel-title {
      font-size: 14px; }
    .patient-card-accordion > .panel-collapse > .panel-body .panel {
      border-radius: 0;
      border-width: 0; }

.patient-card-drag-icon {
  position: absolute;
  left: -15px;
  top: 0;
  bottom: 0;
  height: 15px;
  margin: auto;
  display: inline-block;
  color: #fff; }
  .patient-card-drag-icon .glyphicon-option-vertical {
    width: 5px;
    display: inline-block; }

.patient-card-toggle-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -1px;
  width: 15px;
  height: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto; }

.link:hover, .link:focus {
  text-decoration: underline !important; }

.process-tracker-text {
  font-size: 11px; }

.nowrap {
  white-space: nowrap; }

.move-left-65 {
  position: relative;
  left: -65px; }

.disabled-at {
  background-color: #ddd;
  cursor: not-allowed !important; }

.required-at i {
  border-color: #a94442 !important; }

.input-group-form-control {
  z-index: 1 !important; }
  .input-group-form-control:focus {
    z-index: 1 !important; }

.case-number-value {
  width: 180px;
  margin: 0 5px 0 0;
  display: flex;
  align-items: center; }
  .case-number-value span {
    padding: 6px 12px; }

.col-midsession {
  max-width: 172px; }

.input-midsession {
  min-width: 136px; }

.rounded-circle {
  border-radius: 50% !important;
  width: 32px;
  height: 32px;
  margin-right: auto;
  margin-left: auto; }

.report.bg-warning {
  background-color: #edad69; }

.report.bg-success {
  background-color: #009d0e; }

.report.bg-info {
  background-color: #999999; }

.glyphicon-big {
  font-size: 30px !important; }

.delete-file-icon {
  margin-top: -10px !important;
  color: #a90303; }

.table-striped .header-vertical-middle th {
  vertical-align: middle !important; }

.table-striped .fixed-width .w-60 {
  min-width: 60px; }

.table-striped .fixed-width .w-90 {
  min-width: 60px; }

.table-striped .fixed-width .w-120 {
  min-width: 120px; }

.table-striped .fixed-width .w-150 {
  min-width: 150px; }

.table-striped .fixed-width .w-180 {
  min-width: 180px; }

.table-striped .fixed-width .w-210 {
  min-width: 210px; }

.table-striped .fixed-width .w-240 {
  min-width: 240px; }

.table-striped .fixed-width .w-270 {
  min-width: 270px; }

.table-striped .fixed-width .w-300 {
  min-width: 300px; }

.glyphicon-lightblue {
  color: #2f78e1; }
  .glyphicon-lightblue .disabled {
    opacity: .65;
    cursor: not-allowed; }

.glyphicon-lightred {
  color: #ce292e; }
  .glyphicon-lightred .disabled {
    opacity: .65;
    cursor: not-allowed; }

.glyphicon-medium {
  font-size: 24px; }

.flex-align-end {
  display: flex;
  flex-direction: column;
  align-items: end; }

.center-modal {
  position: fixed;
  top: 15%;
  left: 18.5%;
  z-index: 1050;
  width: 80%;
  height: 80%;
  margin-left: -10%; }

.center-dialog-message {
  position: fixed;
  top: 25%;
  left: 18.5%;
  z-index: 1050;
  width: 80%;
  height: 80%;
  margin-left: -10%; }

td.status-frp {
  min-width: 160px; }

.frp-text-report-status {
  max-width: 141px;
  min-width: 131px; }
  .frp-text-report-status .link {
    color: #a94442 !important; }

.frp-text-patient {
  word-wrap: break-word;
  max-width: 125px; }

.frp-col-report-finalized,
.frp-col-remaining-hours,
.frp-col-authorized-hours {
  max-width: 136px; }

.transparent {
  color: transparent;
  background-color: transparent; }

.table-hover > tbody > tr.not-hover {
  background-color: transparent; }
  .table-hover > tbody > tr.not-hover td {
    border-top: 0px; }

.disabled-color {
  opacity: 0.65; }

.disabled-button {
  opacity: 0.65;
  cursor: not-allowed; }

.ui-select-multiple.ui-select-bootstrap input.ui-select-search {
  padding-top: 5px; }

.ui-select-multiple.ui-select-bootstrap {
  height: auto !important;
  min-height: 34px;
  padding: 3px 3px 0 3px !important;
  max-width: 400px;
  float: right; }

.ui-select-bootstrap .ui-select-choices-row.active > span {
  background: #337ab7 !important;
  color: #fff; }

.col-goal-status {
  min-width: 130px;
  max-width: 160px; }

.col-goal-explanation {
  max-width: 150px; }

.date-of-service-picker {
  margin-left: -5%;
  margin-top: -10px; }

.col-v4-3-5 {
  -ms-flex: 0 0 26.3%;
  flex: 0 0 26.3%;
  max-width: 27%; }

.normal-input-other {
  flex: 0 0 52%;
  max-width: 54%; }

textarea.form-control.vertical-resize-textarea {
  resize: vertical; }

label.tracker-label {
  font-size: 12px; }

.section-timeout-text {
  font-size: 18px; }

.cropArea {
  overflow: hidden;
  width: 550px;
  height: 350px;
  text-align: center; }
  .cropArea img {
    max-width: 100%;
    max-height: 100%; }

img-crop {
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  overflow: hidden; }
  img-crop canvas {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    outline: none;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    /* mobile webkit */ }

/*livechat*/
.chat-iframe-wrap {
  box-shadow: none !important; }

.ie-div-position-customer-chat {
  display: block;
  position: fixed !important;
  right: 0;
  bottom: 0; }

.label-score-rl {
  font-size: 1.3rem;
  min-height: 16px; }

.col-classification {
  min-width: 98px;
  max-width: 98px;
  padding: 0px; }

/*quill-editor*/
.ql-clipboard {
  white-space: pre-wrap; }

.quill-container {
  position: relative;
  width: 100%;
  resize: vertical;
  overflow: auto;
  border: 1px solid #ccc;
  margin: 1px;
  padding-bottom: 12px;
  min-height: 106px; }
  .quill-container .ql-container.ql-snow {
    border: none; }

.resize-handle {
  width: 17px;
  height: 17px;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: nwse-resize;
  /* Use a diagonal resize cursor for usability */
  background-color: #ccc; }

/** patient-case **/
.patient-case .panel-group .panel + .panel {
  margin-top: 2px; }

.patient-case .panel-title > span, .patient-case .panel-title > a {
  font-weight: normal !important; }

.patient-case .panel-title > a {
  cursor: default; }

.patient-case .panel-title > a:hover {
  color: #002D5D;
  text-decoration: none; }

.patient-case .col-title-case {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; }

.patient-case .btn.btn-case {
  padding: 4px 4px;
  font-size: 6px; }

.patient-case .btn.btn-case > span {
  font-size: 10px; }

.patient-case .ui-select-bootstrap > .ui-select-no-choice {
  padding-bottom: 0px; }

.patient-case .btn-default.disabled:hover, .patient-case .btn-default.disabled:focus,
.patient-case .btn-default.disabled.focus, .patient-case .btn-default[disabled]:hover,
.patient-case .btn-default[disabled]:focus, .patient-case .btn-default[disabled].focus {
  background-color: rgba(0, 0, 0, 0.05);
  border-color: #ccc; }

/**
* Pictogrammers Icons & Fonts
 */
.iconify-icon {
  padding-right: 0.6rem;
  font-size: 1.7rem;
  vertical-align: middle; }

li.navbar-border-bottom {
  border-bottom: 2px solid #8b8b8b !important; }

/*======== NPQ Workflow ========*/
.current-indications {
  /* Style for the main list items */
  /* Style for the nested list items */ }
  .current-indications ol > li {
    counter-increment: item; }
  .current-indications ol > li::marker {
    content: counter(item) ". "; }
  .current-indications ol ol {
    counter-reset: subitem; }
  .current-indications ol ol > li {
    counter-increment: subitem; }
  .current-indications ol ol > li::marker {
    content: counter(item) "" counter(subitem, lower-alpha) ". "; }

.agreement-ignore-check {
  font-weight: bold;
  padding-left: 3.6rem; }
;